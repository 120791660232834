// src/components/DeliveryInformation.js
import React from "react";
import { deliveryInformationData } from "./deliveryInformationData";

const DeliveryInformation = () => {
  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
       <h1 className="text-lg lg:text-3xl text-center mb-10 tracking-widest uppercase">Delivery Information</h1>
      <p className="text-gray-600 mb-6">
        We aim to deliver your items as quickly and efficiently as possible. Please find all the information you need about our delivery options below.
      </p>
      <div>
        {deliveryInformationData.map((item, index) => (
          <div key={index} className="mb-6">
            <h2 className="text-xl font-semibold mb-2">{item.title}</h2>
            <p className="text-gray-600 whitespace-pre-line">{item.details}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeliveryInformation;
