// src/components/PrivacyPolicy.js
import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
         <h1 className="text-lg lg:text-3xl text-center mb-10 tracking-widest uppercase">Privacy Policy</h1>
      <p className="text-gray-600 mb-4">
      This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from Fash Attire(www.fashattire.com)
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Information We Collect</h2>
      <p className="mb-4">
        When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the products you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”
      </p>
      <p className="mb-4">
        We collect Device Information using the following technologies:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li className="mb-2">Cookies: Data files that are placed on your device or computer and include an anonymous unique identifier.</li>
        <li className="mb-2">Log Files: Track actions occurring on the Site and collect data including your IP address, browser type, browser version, and the pages of our Site that you visit.</li>
        <li>Web Beacons: Electronic files used to record information about how you browse the Site.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">How We Use Your Information</h2>
      <p className="mb-4">
        We use the information we collect in various ways, including to:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li className="mb-2">Process your transactions and manage your orders.</li>
        <li className="mb-2">Communicate with you about your order and provide customer support.</li>
        <li className="mb-2">Screen our orders for potential risk or fraud.</li>
        <li className="mb-2">Provide you with information or advertising relating to our products or services.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Sharing Your Personal Information</h2>
      <p className="mb-4">
        We share your Personal Information with third parties to help us use your Personal Information, as described above. For example, we may share your information with service providers who assist in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Behavioral Advertising</h2>
      <p className="mb-4">
        We may use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at <a href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work" className="text-blue-600 underline">http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work</a>.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Your Rights</h2>
      <p className="mb-4">
        If you are a European resident, you have the right to access the personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Changes</h2>
      <p className="mb-4">
        We may update this privacy policy from time to time to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Contact Us</h2>
      <p>
        For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at info.fashattire@gmail.com or by mail using the details provided below:
      </p><br />
      <p>Fash Attire</p>
      <p>Abuja, Nigeria</p>
    </div>
  );
};

export default PrivacyPolicy;
