"use client";
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FiMapPin, FiGlobe } from 'react-icons/fi';
import {
  AiOutlineMenu,
  AiOutlineClose,
  AiOutlineShopping,
  AiOutlineRight,
} from 'react-icons/ai';
import { useCart } from '../../Context/CartContext';
import FashLogo from '../../Images/Logo/logo.png';

const Header = () => {
  const { cart } = useCart();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [activeMenu, setActiveMenu] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  console.log(searchResults)
  const navigate = useNavigate();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(prev => !prev);
  };

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isMobileMenuOpen]);

  const searchRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSearchVisible(false);
      }
    };

    if (isSearchVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSearchVisible]);

  const toggleSearch = () => {
    setIsSearchVisible(prev => !prev);
    setSearchResults([]);
  };

  const handleSearch = async () => {
    if (searchTerm.trim() === '') return;

    try {
      const response = await axios.get(`https://fash-backend-1.onrender.com/item/search?name=${searchTerm}`);
      if (response.data && response.data.data) {
        setSearchResults(response.data.data);
        setIsSearchVisible(false);
        navigate('/search-results', { state: { searchResults: response.data.data } });
      }
    } catch (error) {
      console.error('Error fetching search results:', error);
    }

    setSearchTerm('');
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const showSubMenu = (menu) => {
    setActiveMenu(menu);
  };

  const cartItemCount = cart.length;

  const renderDropdown = (title, links, image) => (
    <div className="relative group">
      <Link to={`/${title.toLowerCase()}`} className="hover:underline flex items-center">
        {title}
      </Link>
      <div className="invisible opacity-0 group-hover:visible group-hover:opacity-100 transition-all duration-300 absolute left-0 top-full mt-2 w-[1000px] bg-white shadow-lg rounded-md z-50 p-16 flex">
        <div className="grid grid-cols-3 gap-20 w-3/4">
          {links.map(({ label, href }) => (
            <div key={label}>
              <h4 className="font-bold text-md mb-4">{label}</h4>
              <Link to={href} className="block py-1 hover:text-blue-600">View All {label}</Link>
            </div>
          ))}
        </div>
        <div className="w-4/4">
          <img src={image} width={250} height={250} alt={`${title} Category`} className="object-cover" loading='lazy' />
        </div>
      </div>
    </div>
  );

  return (
    <header className="w-full border-b border-gray-200 text-sm bg-white shadow-sm tracking-widest sticky top-0 z-50">
      <div className="wrapper flex items-center justify-between py-4 font-sans px-6">

        {/* Mobile View */}
        <div className="flex items-center justify-between w-full lg:hidden">
          <div className="text-2xl cursor-pointer" onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
          </div>
          <Link to="/" className="flex items-center">
            <img src={FashLogo} width={70} height={48} alt="Fash Logo" />
          </Link>
          <div className="flex items-center space-x-4">
<span className="text-xs cursor-pointer" onClick={toggleSearch}>
      SEARCH
    </span>            <Link to="/bag" className="relative">
              <AiOutlineShopping className="text-xl" />
              {cartItemCount > 0 && (
                <span className="absolute top-[-10px] right-[-10px] bg-red-600 text-white text-xs rounded-full px-1 py-0.5 shadow-sm">
                  {cartItemCount}
                </span>
              )}
            </Link>
          </div>
        </div>

        {/* Desktop View */}
        <div className="hidden lg:flex items-center justify-between w-full relative">
          <div className="flex items-center space-x-10">
            <Link to="/" className="flex items-center">
              <img src={FashLogo} width={80} height={48} alt="Fash Logo" priority />
            </Link>
            <nav className="flex items-center space-x-12">
              {renderDropdown('MEN', [
                { label: 'NEW ARRIVALS', href: '/men' },
                { label: 'SHOES', href: '/men/shoes' },
                { label: 'BOOTS', href: '/men/boots' },
                { label: 'SHIRTS', href: '/men/shirts' },
                { label: 'TROUSERS', href: '/men/trousers' },
                { label: 'OUTERWEAR', href: '/men/outwears' },
                { label: 'JACKETS', href: '/men/jackets' },
              ], "https://res.cloudinary.com/deofmlywm/image/upload/v1730097914/IMG_8440_xkdtvg.webp")}

              {renderDropdown('WOMEN', [
                { label: 'NEW ARRIVALS', href: '/women' },
                { label: 'SHOES', href: '/women/shoes' },
                { label: 'BOOTS', href: '/women/boots' },
                { label: 'SHIRTS', href: '/women/shirts' },
                { label: 'TROUSERS', href: '/women/trousers' },
                { label: 'OUTERWEAR', href: '/women/outwears' },
                { label: 'JACKETS', href: '/women/jackets' },
              ], "https://res.cloudinary.com/deofmlywm/image/upload/v1730098022/IMG_8371_nchqob.webp")}

              {renderDropdown('COLLECTION', [
                { label: 'COLLECTIONS', href: '/collection' },
              ], "https://res.cloudinary.com/deofmlywm/image/upload/v1730097915/IMG_8722_h7p9xm.webp")}

              <Link to="/contact" className="hover:underline">CONTACT</Link>
            </nav>
          </div>
          <div className="flex items-center space-x-6">
            <span className="text-md cursor-pointer" onClick={toggleSearch}>
              SEARCH
            </span>
            <Link to="/bag" className="relative">
              <AiOutlineShopping className="text-xl" />
              {cartItemCount > 0 && (
                <span className="absolute top-[-10px] right-[-10px] bg-red-600 text-white text-xs rounded-full px-2 py-1">
                  {cartItemCount}
                </span>
              )}
            </Link>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`lg:hidden fixed inset-y-0 left-0 right-[20%] bg-white z-50 p-6 overflow-y-auto transition-transform duration-500 ease-in-out transform ${
          isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        {activeMenu === '' ? (
          // Main Mobile Menu
          <div>
            <nav className="flex flex-col space-y-16 mt-20 font-bold tracking-widest">
              <div onClick={() => showSubMenu('men')} className="hover:text-black transition-all duration-200 cursor-pointer flex justify-between items-center">
                MEN <AiOutlineRight className="text-sm" />
              </div>
              <hr />
              <div onClick={() => showSubMenu('women')} className="hover:text-black transition-all duration-200 cursor-pointer flex justify-between items-center">
                WOMEN <AiOutlineRight className="text-sm" />
              </div>
              <hr />
              <div onClick={() => showSubMenu('collection')} className="hover:text-black transition-all duration-200 cursor-pointer flex justify-between items-center">
                COLLECTION <AiOutlineRight className="text-sm" />
              </div>
              <hr />
              <Link to="/contact" className="hover:text-black transition-all duration-200">CONTACT</Link>
              <hr />
            </nav>
            <div className="mt-20 space-y-8 pt-4 ">
          
          <div className="flex items-center space-x-3">
            <FiMapPin className="text-2xl" />
            <span className='text-md uppercase'>Store Locator</span>
          </div>
          
         
         
          <div className="flex items-center space-x-3">
            <FiGlobe className="text-2xl" />
            <span className='text-md uppercase'>Shipping to: International Shipping</span>
          </div>
        </div>
          </div>
        ) : (
          // Submenus would go here
          <div>
            <div className="flex items-center justify-between mb-8">
              <span className="cursor-pointer font-bold text-gray-500" onClick={() => setActiveMenu('')}>
                Back
              </span>
            </div>
           <nav className="flex flex-col space-y-16 mt-20 font-bold tracking-widest text-sm">
        {activeMenu === 'collection' ? (
          <>
            <Link to="/collection" className="hover:text-black transition-all duration-200" onClick={toggleMobileMenu}>View All Collections</Link>
            <hr />
          </>
        ) : activeMenu === 'men' ? (
          <>
            <Link to="/men" className="hover:text-black transition-all duration-200" onClick={toggleMobileMenu}>View All New Arrivals</Link>
            <hr />
            <Link to="/men/shoes" className="hover:text-black transition-all duration-200" onClick={toggleMobileMenu}>View All Shoes</Link>
            <hr />
            <Link to="/men/boots" className="hover:text-black transition-all duration-200" onClick={toggleMobileMenu}>View All Boots</Link>
            <hr />
            <Link to="/men/shirts" className="hover:text-black transition-all duration-200" onClick={toggleMobileMenu}>View All Shirts</Link>
            <hr />
            <Link to="/men/trousers" className="hover:text-black transition-all duration-200" onClick={toggleMobileMenu}>View All Trousers</Link>
            <hr />
            <Link to="/men/outwears" className="hover:text-black transition-all duration-200" onClick={toggleMobileMenu}>View All Outwears</Link>
            <hr />
            <Link to="/men/jackets" className="hover:text-black transition-all duration-200" onClick={toggleMobileMenu}>View All Jackets</Link>
          </>
        ) : (
          <>
            <Link to="/women" className="hover:text-black transition-all duration-200" onClick={toggleMobileMenu}>View All New Arrivals</Link>
            <hr />
            <Link to="/women/shoes" className="hover:text-black transition-all duration-200" onClick={toggleMobileMenu}>View All Shoes</Link>
            <hr />
            <Link to="/women/boots" className="hover:text-black transition-all duration-200" onClick={toggleMobileMenu}>View All Boots</Link>
            <hr />
            <Link to="/women/shirts" className="hover:text-black transition-all duration-200" onClick={toggleMobileMenu}>View All Shirts</Link>
            <hr />
            <Link to="/women/trousers" className="hover:text-black transition-all duration-200" onClick={toggleMobileMenu}>View All Trousers</Link>
            <hr />
            <Link to="/women/outwears" className="hover:text-black transition-all duration-200" onClick={toggleMobileMenu}>View All Outwears</Link>
            <hr />
            <Link to="/women/jackets" className="hover:text-black transition-all duration-200" onClick={toggleMobileMenu}>View All Jackets</Link>
          </>
        )}
      </nav>
          </div>
        )}
      </div>
 {isMobileMenuOpen && (
  <div
    className="absolute top-96 right-4 bg-black bg-opacity-20 rounded-full p-2 cursor-pointer z-50 md:hidden"
    onClick={toggleMobileMenu}
  >
    <AiOutlineClose className="text-3xl text-white" />
  </div>
)}



{isSearchVisible && (
        <div ref={searchRef} className="flex justify-center mt-2 mb-10">
          <input
            type="text"
            placeholder="Search..."
            className="border border-gray-300 rounded p-2"
            value={searchTerm}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <button onClick={handleSearch} className="ml-2 px-4 py-2 bg-black text-white">
            Search
          </button>
        </div>
      )}
    </header>
  );
};

export default Header;
