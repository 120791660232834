import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Spinner = () => (
  <div className="flex justify-center items-center min-h-[400px]">
    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-black border-opacity-75"></div>
  </div>
);

const ProductCard = ({ product }) => {
  const [isHovered, setIsHovered] = useState(false);

  // Function to switch between main image and hover image
  const getProductImageUrl = () => {
    return isHovered && product.hover_Url ? product.hover_Url : product.img_Url;
  };

  return (
    <Link
      to={`/products/${product._id}`}
      className="w-full p-2 rounded-lg"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={getProductImageUrl()}
        alt={product.name}
        loading="lazy"
        className="w-full h-56 md:h-96 object-cover mb-2"
      />
      <h2 className="text-xs md:text-sm font-bold uppercase">{product.name}</h2>
      <p className="text-xs mt-2 mb-2">${product.price}</p>
      <div className="mt-1 flex space-x-1">
        {product.color.map((color, index) => (
          <button key={index} className="w-4 h-4" style={{ backgroundColor: color }} />
        ))}
      </div>
    </Link>
  );
};


const ProductAdd = ({ gender }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsToShow = 4;

  const [userPosts, setUserPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch('https://fash-backend-1.onrender.com/item/store');
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        const data = await response.json();
        setUserPosts(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  // Filter products by gender based on the current product's gender
  const productsToDisplay = userPosts.filter(product => product.sex === gender);

  const handlePrev = () => {
    setCurrentIndex(prevIndex => Math.max(prevIndex - itemsToShow, 0));
  };

  const handleNext = () => {
    setCurrentIndex(prevIndex => Math.min(prevIndex + itemsToShow, productsToDisplay.length - itemsToShow));
  };

  if (loading) return <Spinner />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4 text-xs py-10 lg:py-16">
      <h1 className="text-lg lg:text-3xl text-center mb-10 tracking-widest">YOU MAY ALSO LIKE</h1>
      <div className="flex justify-between mb-8">
        <button onClick={handlePrev} className="flex items-center px-4 py-2 text-black transition duration-300 hover:underline tracking-wider" disabled={currentIndex === 0}>
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" /> PREV
        </button>
        <button onClick={handleNext} className="flex items-center px-4 py-2 text-black transition duration-300 hover:underline tracking-wider" disabled={currentIndex + itemsToShow >= productsToDisplay.length}>
          NEXT <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
        </button>
      </div>

      <div className="grid grid-cols-2 lg:grid-cols-4 gap-0 md:gap-4">
        {productsToDisplay.slice(currentIndex, currentIndex + itemsToShow).map(product => (
          <ProductCard key={product._id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default ProductAdd;
