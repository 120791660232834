import React, { useState } from "react";
import axios from "axios";
import { FaInstagram, FaTiktok } from "react-icons/fa";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setMessage(""); // Clear previous messages

    try {
      const response = await axios.post(
        `https://api.convertkit.com/v3/forms/7330678/subscribe`,
        {
          api_key: "orcj11jDI14VkybpDd91mA",
          email,
        }
      );
      if (response.data) {
        setMessage("Successfully subscribed!");
        setEmail(""); // Clear the input field
      }
    } catch (error) {
      setMessage("Failed to subscribe. Please try again.");
    }
  };

  return (
    <footer className="bg-white text-black py-10 border-t border-gray-300 tracking-widest">
      <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 gap-4 sm:grid-cols-4 lg:grid-cols-5">
          <div className="col-span-2 sm:col-span-1">
            <h5 className="font-semibold uppercase text-gray-800 text-sm">Newsletter</h5>
            <p className="mt-2 text-gray-600 text-xs">Subscribe to receive updates, access to exclusive deals, and more.</p>
            <form onSubmit={handleSubscribe} className="mt-4 flex flex-row space-x-2 sm:flex-col sm:space-x-0 sm:space-y-2">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 focus:ring-black focus:border-black text-xs"
                required
              />
              <button
                type="submit"
                className="w-full bg-black text-white px-4 py-2 text-xs"
              >
                SUBSCRIBE
              </button>
            </form>
            {message && <p className="text-xs text-gray-600 mt-2">{message}</p>}
          </div>

          <div className="mt-5 lg:mt-0 lg:ml-5">
            <h3 className="font-semibold uppercase text-gray-800 text-sm">Support</h3>
            <ul className="mt-4 space-y-2 text-xs">
              <li><a href="/contact" className="text-gray-600 hover:text-black">Contact Us</a></li>
              <li><a href="/faq" className="text-gray-600 hover:text-black">FAQs</a></li>
              <li><a href="/ReturnsAndRefunds" className="text-gray-600 hover:text-black">Returns & Refunds</a></li>
              <li><a href="/DeliveryInformation" className="text-gray-600 hover:text-black">Delivery Information</a></li>
              <li><a href="/InternationalCharges" className="text-gray-600 hover:text-black">International Charges</a></li>
              <li><a href="/SizeGuide" className="text-gray-600 hover:text-black">Size Guide</a></li>
            </ul>
          </div>
          <div className="mt-5 lg:mt-0">
            <h3 className="font-semibold uppercase text-gray-800 text-sm">Legal</h3>
            <ul className="mt-4 space-y-2 text-xs">
              <li><a href="/PrivacyPolicy" className="text-gray-600 hover:text-black">Privacy Policy</a></li>
              <li><a href="/TermsOfService" className="text-gray-600 hover:text-black">Terms of Service</a></li>
            </ul>
          </div>

          <div className="mt-5 lg:mt-0">
            <h3 className="font-semibold uppercase text-gray-800 text-sm">About Fash</h3>
            <ul className="mt-4 space-y-2 text-xs">
              <li><a href="/AboutUs" className="text-gray-600 hover:text-black">About Us</a></li>
              <li><a href="/CorporateResponsibility" className="text-gray-600 hover:text-black">Corporate Responsibility</a></li>
              <li><button className="text-gray-600 hover:text-black cursor-default">Store Locator</button></li>
              </ul>
          </div>

       <div className="mt-5 lg:mt-0">
            <h3 className="font-semibold uppercase text-gray-800 text-sm">Language</h3>
            <p className="mt-2 text-gray-600 text-xs">English</p>
          </div>
        </div>

        <div className="mt-8 pt-4 flex flex-col items-center justify-center sm:flex-row sm:justify-between">
          <p className="text-gray-600 text-xs">© Fash, 2024</p>
          <div className="flex space-x-4 mt-2 sm:mt-0">
            <a href="https://www.instagram.com/fash.attire/" className="text-gray-600 hover:text-black" target="_blank" rel="noopener noreferrer"><FaInstagram size={15} /></a>
            <a href="https://www.tiktok.com/@fashattire" className="text-gray-600 hover:text-black" target="_blank" rel="noopener noreferrer"><FaTiktok size={15} /></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
