// App.js
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import ProductDetails from "./Component/products/ProductDetails";
import HomePage from "./Component/Home/HomePage";
import Header from "./Component/header/Header";
import Footer from "./Component/Footer/Footer";
import ContactForm from "./Component/Contact/ContactForm";
import Collection from "./Component/Collection/Collection.jsx";
import CollectionDetails from "./Component/Collection/CollectionDetails.jsx";
import Men from "./Component/Men/Men";
import Women from "./Component/Women/Women";
import { CartProvider } from "./Context/CartContext";
import Bag from "./Component/Bag/Bag";
import Checkout from "./Component/Checkout/Checkout";
import SuccessPayment from "./Component/Payment/SuccessPayment";
import FailedPayment from "./Component/Payment/FailedPayment";

// Admin imports
import AdminRoutes from "./admin/AdminRoutes";
import AdminLayout from "./admin/AdminLayout";
import MenShoes from "./Component/Men/MenShoes";
import Faq from "./Component/Faq/Faq";
import ReturnsAndRefunds from "./Component/ReturnsandRefunds/ReturnsAndRefunds";
import DeliveryInformation from "./Component/DeliveryInformation/DeliveryInformation";
import InternationalCharges from "./Component/InternationalCharges/InternationalCharges";
import SizeGuide from "./Component/SizeGuide/SizeGuide";
import TermsOfService from "./Component/TermsOfService/TermsOfService.jsx";
import CorporateResponsibility from "./Component/CorporateResponsibility/CorporateResponsibility.jsx";
import PrivacyPolicy from "./Component/PrivacyPolicy/PrivacyPolicy.jsx";
import MenTrousers from "./Component/Men/MenTrousers.jsx";
import MenOutwears from "./Component/Men/MenOutwears.jsx";
import MenShirts from "./Component/Men/MenShirts.jsx";
import WomenOuterwears from "./Component/Women/WomenOuterwears.jsx";
import WomenTrousers from "./Component/Women/WomenTrousers.jsx";
import WomenShoes from "./Component/Women/WomenShoes.jsx";
import WomenShirts from "./Component/Women/WomenShirts.jsx";
import MenJacket from "./Component/Men/MenJacket.jsx";
import Success from "./Component/Success/Success.jsx";
import SearchResults from "./Component/SearchResults.js";
import MenBoots from "./Component/Men/MenBoots.jsx";
import WomenBoots from "./Component/Women/WomenBoots.jsx";
import AboutUs from "./Component/AboutUs/AboutUs.jsx";
import WomenJacket from "./Component/Women/WomenJacket.jsx";
import ScrollToTop from "./ScrollToTop.js";

const SuccessPage = () => {
  const location = useLocation();

  // Function to get query parameters
  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      trxref: params.get('trxref'),   // Extract trxref
      reference: params.get('reference'), // Extract reference
    };
  };

  // Extract the parameters
  const { trxref, reference } = getQueryParams();

  return <Success trxref={trxref} reference={reference} />; // Pass both as props to Success
};

function App() {
  return (
    <CartProvider>
      <Router>
      <ScrollToTop /> 
        <Routes>
          {/* Customer-facing routes (with Header and Footer) */}
          <Route
            path="/"
            element={
              <>
                <Header />
                <HomePage />
                <Footer />
              </>
            }
          />
           <Route
          path="/search-results" 
            element={
              <>
                <Header />
                <SearchResults />
                <Footer />
              </>
            }
          />

          <Route
            path="/contact"
            element={
              <>
                <Header />
                <ContactForm />
                <Footer />
              </>
            }
          />
          <Route
            path="/collection"
            element={
              <>
                <Header />
                <Collection />
                <Footer />
              </>
            }
          />
          <Route
            path="/collection/:id"
            element={
              <>
                <Header />
                <CollectionDetails />
                <Footer />
              </>
            }
          />
          <Route
            path="/men"
            element={
              <>
                <Header />
                <Men />
                <Footer />
              </>
            }
          />
          <Route
            path="/men/shoes"
            element={
              <>
                <Header />
                <MenShoes />
                <Footer />
              </>
            }
          />

<Route
            path="/men/boots"
            element={
              <>
                <Header />
                <MenBoots />
                <Footer />
              </>
            }
          />
          <Route
            path="/men/outwears"
            element={
              <>
                <Header />
                <MenOutwears />
                <Footer />
              </>
            }
          />
          <Route
            path="/men/shirts"
            element={
              <>
                <Header />
                <MenShirts />
                <Footer />
              </>
            }
          />
          <Route
            path="/men/jackets"
            element={
              <>
                <Header />
                <MenJacket />
                <Footer />
              </>
            }
          />
          <Route
            path="/men/trousers"
            element={
              <>
                <Header />
                <MenTrousers />
                <Footer />
              </>
            }
          />
          <Route
            path="/women"
            element={
              <>
                <Header />
                <Women />
                <Footer />
              </>
            }
          />
          <Route
            path="/women/shirts"
            element={
              <>
                <Header />
                <WomenShirts />
                <Footer />
              </>
            }
          />
          <Route
            path="/women/shoes"
            element={
              <>
                <Header />
                <WomenShoes />
                <Footer />
              </>
            }
          />
            <Route
            path="/women/boots"
            element={
              <>
                <Header />
                <WomenBoots />
                <Footer />
              </>
            }
          />
          <Route
            path="/women/trousers"
            element={
              <>
                <Header />
                <WomenTrousers />
                <Footer />
              </>
            }
          />
          <Route
            path="/women/outwears"
            element={
              <>
                <Header />
                <WomenOuterwears />
                <Footer />
              </>
            }
          />
           <Route
            path="/women/jackets"
            element={
              <>
                <Header />
                <WomenJacket />
                <Footer />
              </>
            }
          />
          <Route
            path="/bag"
            element={
              <>
                <Header />
                <Bag />
                <Footer />
              </>
            }
          />
          <Route
            path="/checkout"
            element={
              <>
                <Header />
                <Checkout />
                <Footer />
              </>
            }
          />
          <Route
            path="/success-payment"
            element={
              <>
                <Header />
                <SuccessPayment />
                <Footer />
              </>
            }
          />
          <Route
            path="/failed-payment"
            element={
              <>
                <Header />
                <FailedPayment />
                <Footer />
              </>
            }
          />
          <Route path="/success-page" element={<SuccessPage />} />
          <Route
            path="/products/:id"
            element={
              <>
                <Header />
                <ProductDetails />
                <Footer />
              </>
            }
          />
          <Route
            path="/faq"
            element={
              <>
                <Header />
                <Faq />
                <Footer />
              </>
            }
          />
          <Route
            path="/ReturnsAndRefunds"
            element={
              <>
                <Header />
                <ReturnsAndRefunds />
                <Footer />
              </>
            }
          />
          <Route
            path="/InternationalCharges"
            element={
              <>
                <Header />
                <InternationalCharges />
                <Footer />
              </>
            }
          />
          <Route
            path="/SizeGuide"
            element={
              <>
                <Header />
                <SizeGuide />
                <Footer />
              </>
            }
          />
          <Route
            path="/PrivacyPolicy"
            element={
              <>
                <Header />
                <PrivacyPolicy />
                <Footer />
              </>
            }
          />
          <Route
            path="/TermsOfService"
            element={
              <>
                <Header />
                <TermsOfService />
                <Footer />
              </>
            }
          />
          <Route
            path="/DeliveryInformation"
            element={
              <>
                <Header />
                <DeliveryInformation />
                <Footer />
              </>
            }
          />
             <Route
            path="/AboutUs"
            element={
              <>
                <Header />
                <AboutUs />
                <Footer />
              </>
            }
          />
          <Route
            path="/CorporateResponsibility"
            element={
              <>
                <Header />
                <CorporateResponsibility />
                <Footer />
              </>
            }
          />
          {/* Admin routes (without Header and Footer) */}
          <Route
            path="/admin/*"
            element={
              <AdminLayout>
                <AdminRoutes />
              </AdminLayout>
            }
          />
        </Routes>
      </Router>
    </CartProvider>
  );
}

export default App;
