// src/data/deliveryInformationData.js
export const deliveryInformationData = [
    {
      title: "Shipping Options",
      details: "We offer Standard, Expedited, and Express shipping options. Choose your preferred option at checkout to get your items when you need them."
    },
    {
      title: "Estimated Delivery Times",
      details: "Standard shipping: 5-7 business days\nExpedited shipping: 2-3 business days\nExpress shipping: 1-2 business days\nDelivery times may vary depending on your location."
    },
    {
      title: "Shipping Costs",
      details: "Shipping costs are calculated based on your location and the weight of your order. The exact amount will be displayed at checkout. For orders over $100, we offer free standard shipping within the US."
    },
    {
      title: "International Shipping",
      details: "We ship to select international locations. Shipping fees and delivery times vary by region. Please note that international customers are responsible for any customs or import duties."
    },
    {
      title: "Order Tracking",
      details: "Once your order is shipped, you will receive a tracking number via email. You can use this number to track your package on our website or the carrier's site."
    },
    {
      title: "Shipping Restrictions",
      details: "We currently do not ship to PO boxes or military addresses. Please provide a valid residential or business address for delivery."
    },
    {
      title: "Lost or Delayed Packages",
      details: "If your package is delayed or appears to be lost, please reach out to our customer support team with your order number, and we’ll help you locate it or arrange a replacement if necessary."
    },
  ];
  