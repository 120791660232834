// src/components/TermsOfService.js
import React from "react";

const TermsOfService = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8">Terms of Service</h1>

      <p className="mb-4">
        These Terms of Service govern your use of the website located at Fash Attire (www.fashattire.com)and your purchase of products from Fash Attire. By accessing or using the Site, you agree to be bound by these Terms. If you do not agree to these Terms, you may not access the Site or purchase products.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">1. Eligibility</h2>
      <p className="mb-4">
        You must be at least 18 years old to use this Site or purchase products. By using the Site, you represent and warrant that you meet this eligibility requirement.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">2. Account Registration</h2>
      <p className="mb-4">
        You may be required to create an account to access certain features of the Site. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for safeguarding your password and other account credentials and for any activities or actions under your account.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">3. Orders and Payments</h2>
      <p className="mb-4">
        All orders placed through the Site are subject to our acceptance. We may refuse or cancel any order for reasons including but not limited to product availability, errors in the description or price of the product, or any other reason. Payment methods accepted on the Site will be specified at the time of purchase.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">4. Returns and Refunds</h2>
      <p className="mb-4">
        Please refer to our Returns & Refunds policy for information on returning products and obtaining refunds.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">5. Intellectual Property</h2>
      <p className="mb-4">
        All content on the Site, including text, graphics, logos, images, and software, is the property of Fash Attire or its licensors and is protected by copyright, trademark, and other intellectual property laws. You may not use, reproduce, modify, or distribute any content without our prior written consent.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">6. Limitation of Liability</h2>
      <p className="mb-4">
        To the fullest extent permitted by law, [Your Brand Name] shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising from your use of the Site or any products purchased through the Site.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">7. Indemnification</h2>
      <p className="mb-4">
        You agree to indemnify and hold harmless [Your Brand Name], its affiliates, and their respective officers, directors, employees, and agents from any claims, losses, liabilities, damages, costs, or expenses, including reasonable attorneys' fees, arising out of or related to your use of the Site or your violation of these Terms.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">8. Governing Law</h2>
      <p className="mb-4">
        These Terms shall be governed by and construed in accordance with the laws of Federal Capital Territory, Abuja, Nigeria without regard to its conflict of law principles.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">9. Changes to These Terms</h2>
      <p className="mb-4">
        We reserve the right to modify these Terms at any time. Any changes will be effective when we post the revised Terms on the Site. Your continued use of the Site after any such changes constitutes your acceptance of the new Terms.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">10. Contact Us</h2>
      <p className="mb-4">
        If you have any questions about these Terms, please contact us at:
      </p>
      <p>Fash Attire</p>
      <p>Abuja, Nigeria</p>
    </div>
  );
};

export default TermsOfService;
