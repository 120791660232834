import React, { useEffect, useState } from 'react';
import { useCart } from '../../Context/CartContext';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import ProductAdd from './ProductAdd';


const Spinner = () => (
  <div className="flex justify-center items-center min-h-[400px]">
    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-black border-opacity-75"></div>
  </div>
);

export default function ProductDetails() {
  const { id } = useParams(); // Use useParams to get the product ID
  const [product, setProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedSize, setSelectedSize] = useState('');
  const [openSection, setOpenSection] = useState(null);
  const [isAdded, setIsAdded] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [imageIndex, setImageIndex] = useState(0); // Add state for the image index


  const { addToCart } = useCart();
  const navigate = useNavigate();


  useEffect(() => {
    const fetchProduct = async () => {
      const res = await fetch(`https://fash-backend-1.onrender.com/item/${id}`, {
        cache: 'no-store',
      });

      if (!res.ok) {
        console.error('Failed to fetch product:', res.status, res.statusText);
        return null;
      }

      const productData = await res.json();
      setProduct(productData);
      setSelectedImage(productData.img_Url);
      setSelectedColor(productData.color[0] || '');
      setSelectedSize(productData.size[0] || '');
    };

    fetchProduct();
  }, [id]); // Use id instead of params.id

  const toggleSection = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  
const handleBuyNow = () => {
  if (!product) return;

  addToCart({
    id: product._id || product.id,
    name: product.name,
    image: selectedImage,
    price: product.price,
    color: selectedColor || 'Default Color',
    size: selectedSize || 'Default Size',
    quantity: 1,
  });

  navigate('/checkout'); // Navigate to the checkout page directly
};

  const handleAddToCart = () => {
    if (!product) return;

    addToCart({
      id: product._id || product.id,
      name: product.name,
      image: selectedImage,
      price: product.price,
      color: selectedColor || 'Default Color',
      size: selectedSize || 'Default Size',
      quantity: 1,
    });

    setIsAdded(true);
    setIsButtonClicked(true);
    setTimeout(() => setIsButtonClicked(false), 300);
    setTimeout(() => setIsAdded(false), 2000);

    fetch('https://fash-backend-1.onrender.com/order', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        productId: product._id || product.id,
        color: selectedColor || 'Default Color',
        size: selectedSize || 'Default Size',
        quantity: 1,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log('Order created:', data);
      })
      .catch((err) => {
        console.error('Error creating order:', err);
      });
  };

  if (!product) {
    return <Spinner />;
  }

 
    // Functions to handle image navigation
    const handlePrevImage = () => {
      setImageIndex((prevIndex) => (prevIndex === 0 ? product.img_Arr_Url.length - 1 : prevIndex - 1));
    };
  
    const handleNextImage = () => {
      setImageIndex((prevIndex) => (prevIndex === product.img_Arr_Url.length - 1 ? 0 : prevIndex + 1));
    };
  return (
    <><div className="container mx-auto py-10 px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row">
      <div className="w-full lg:w-1/2 mb-10 lg:mb-0">
        <div className="mb-4 text-sm">
          <span>{product.sex.toUpperCase()} &gt; </span>
          <span className="tracking-widest">{product.category.toUpperCase()} &gt; </span>
          <span className="tracking-widest">{product.name.toUpperCase()}</span>
        </div>

        <div className="flex flex-col lg:flex-row">
          <div className="flex lg:flex-col space-x-4 lg:space-x-0 lg:space-y-4 lg:mr-4 justify-center lg:justify-start mb-4 lg:mb-0">
            {product.img_Arr_Url.map((image, index) => (
              <button
                key={index}
                className={`relative w-16 h-16 sm:w-20 sm:h-14 lg:w-14 lg:h-14 ${selectedImage === image ? '' : 'border-gray-300'}`}
                onClick={() => setSelectedImage(image)}
              >
                <img
                  src={image}
                  alt={product.name} // Simplified alt text
                  className="rounded cursor-pointer object-cover w-full h-full" />
              </button>
            ))}
          </div>

          <div className="flex-1 relative">
            <div className="relative w-full h-[400px] sm:h-[600px]">
              <img
                src={product.img_Arr_Url[imageIndex]} // Use the image at the current index
                alt={product.name}
                className="object-cover w-full h-full" />
            </div>

            {/* Navigation buttons for image carousel */}
            <button
              onClick={handlePrevImage}
              className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-500 text-black w-8 h-8 flex items-center justify-center rounded-full"
            >
              &lt;
            </button>
            <button
              onClick={handleNextImage}
              className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-500 text-black w-8 h-8 flex items-center justify-center rounded-full"
            >
              &gt;
            </button>

          </div>
        </div>
      </div>

      <div className="w-full lg:w-1/2 lg:pl-16 space-y-5">
        <h1 className="text-lg md:text-2xl lg:mt-10 font-bold uppercase tracking-wide">{product.name}</h1>
        <p className="text-sm md:text-md font-semibold">{product._collection}</p>
        <p className="text-md sm:text-lg lg:text-xl">${product.price.toFixed(2)}</p>

        <div>
          <p className="text-sm lg:text-md">COLOR: {selectedColor.toUpperCase()}</p>
          <div className="flex space-x-4 lg:mt-2">
            {product.color.map((color, index) => (
              <button
                key={index}
                className={`h-6 w-6 border ${selectedColor === color ? 'border-black' : 'border-gray-300'} cursor-pointer`}
                style={{ backgroundColor: color.toLowerCase() }}
                onClick={() => setSelectedColor(color)} />
            ))}
          </div>
        </div>

        <div>
          <p className="text-sm lg:text-md mt-10">SIZE: {selectedSize || 'Select a size'}</p>
          <div className="grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-6 gap-2 lg:mt-2">
            {product.size.map((size) => (
              <button
                key={size}
                className={`border py-2 text-sm ${selectedSize === size ? 'border-black' : ''}`}
                onClick={() => setSelectedSize(size)}
              >
                {size}
              </button>
            ))}
          </div>
        </div>

        <button
          className={`w-full bg-black text-white py-3 tracking-widest transition-transform ${isButtonClicked ? 'scale-105' : ''}`}
          onClick={handleAddToCart}
        >
          ADD TO BAG
        </button>

        <button
  className="w-full bg-gray-600 text-white py-3 mt-2 tracking-widest transition-transform"
  onClick={handleBuyNow}
>
  BUY NOW
</button>


      


        {isAdded && (
          <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded shadow-lg animate-fadeInOut">
            Added to Bag
          </div>
        )}

        <div className="space-y-4">
          {[
            // { title: 'DETAILS', content: product.detail || 'No details available.' }, 
            { title: 'DESCRIPTION', content: product.description || 'No description available.' },
            // { title: 'SHIPPING & RETURNS', content: 'Shipping and returns policy...' } 
          ].map((section, index) => (
            <div key={index}>
              <button onClick={() => toggleSection(index)} className="w-full flex justify-between border-b py-2">
                <span className="text-sm tracking-widest">{section.title}</span>
                <span>{openSection === index ? '-' : '+'}</span>
              </button>
              {openSection === index && <p className="mt-2">{section.content}</p>}
            </div>
          ))}
        </div>

      </div>
    </div>
    
    
    <ProductAdd gender={product.sex} />

    
    </>
  );
}
