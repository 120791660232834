import React, { useEffect, useState } from 'react';
import { HiExternalLink } from 'react-icons/hi';
import { Link } from 'react-router-dom';

const ProductInventory = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch('https://fash-backend-1.onrender.com/item')
      .then((response) => response.json())
      .then((data) => {
        // Filtering out archived products
        const activeProducts = data.filter((product) => !product.isArchived);
        setProducts(activeProducts);
      })
      .catch((error) => {
        console.error('Error fetching inventory:', error);
      });
  }, []);

  const getStockStatus = (quantity) => {
    if (quantity === 0) return 'NO STOCK';
    if (quantity < 10) return 'LOW STOCK';
    return 'IN STOCK';
  };

  const getStockColor = (status) => {
    switch (status) {
      case 'NO STOCK':
        return 'text-red-500';
      case 'LOW STOCK':
        return 'text-yellow-500';
      case 'IN STOCK':
        return 'text-green-500';
      default:
        return '';
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md relative">
      <h2 className="text-lg font-semibold">Product Inventory</h2>
      <Link to="/admin/product" className="absolute top-4 right-4 text-black">
        <HiExternalLink size={20} />
      </Link>
      <ul className="mt-6 space-y-6">
        {products.slice(0, 4).map((product) => { // Limit to 4 products
          const stockStatus = getStockStatus(product.quantity);
          return (
            <li key={product._id} className="flex justify-between items-center">
              <div>
                <p className="text-black font-bold">{product.name}</p>
                <p className="text-sm text-gray-500">Quantity: {product.quantity}</p>
              </div>
              <p className={`${getStockColor(stockStatus)} text-sm font-bold`}>
                {stockStatus}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ProductInventory;
