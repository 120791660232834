import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const Products = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('All');
  const [productToDelete, setProductToDelete] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [actionMenu, setActionMenu] = useState(null);

  // Fetch products from the backend
  const fetchProducts = useCallback(async () => {
    try {
      const response = await fetch('https://fash-backend-1.onrender.com/item');
      const data = await response.json();
      setProducts(data);
      applyFilter(data, selectedFilter);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  }, [selectedFilter]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  // Apply the filter based on selectedFilter
  const applyFilter = (products, filter) => {
    let filtered;
    if (filter === 'All') {
      filtered = products;
    } else if (filter === 'Active') {
      filtered = products.filter(product => !product.isArchived);
    } else if (filter === 'Archived') {
      filtered = products.filter(product => product.isArchived);
    }
    setFilteredProducts(filtered);
  };

  // Handle filter button clicks
  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    applyFilter(products, filter);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setProductToDelete(null);
  };

  const handleDelete = async (productId) => {
    try {
      const response = await fetch(`https://fash-backend-1.onrender.com/item/${productId}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        await fetchProducts(); // Re-fetch products to update the list after deletion
        setShowModal(false);
      } else {
        console.error('Failed to delete product');
      }
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const confirmDelete = (product) => {
    setProductToDelete(product);
    setShowModal(true);
  };

  const handleActionMenuToggle = (productId) => {
    setActionMenu((prev) => (prev === productId ? null : productId));
  };

  // Calculate total inventory volume
  const totalInventoryVolume = products.reduce((acc, product) => acc + (product.quantity || 0), 0);
  const totalInventorySold = products.reduce((acc, product) => acc + (product.topProducts || 0), 0);

  return (
    <div className="relative p-8">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <div className="flex space-x-4">
          <div className="bg-black text-white p-4 rounded-lg">
            <p className="text-xl font-semibold">{totalInventoryVolume}</p>
            <p className="text-sm">Total inventory volume</p>
          </div>
          <div className="bg-gray-200 p-4 rounded-lg">
            <p className="text-xl font-semibold">{totalInventorySold}</p>
            <p className="text-sm">Total inventory sold</p>
          </div>
        </div>
      </div>

      {/* Filters and Add Collection Button */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex space-x-4">
          <button
            className={`py-2 px-4 rounded-md ${selectedFilter === 'All' ? 'bg-black text-white' : 'bg-gray-200'}`}
            onClick={() => handleFilterChange('All')}
          >
            All
          </button>
          <button
            className={`py-2 px-4 rounded-md ${selectedFilter === 'Active' ? 'bg-black text-white' : 'bg-gray-200'}`}
            onClick={() => handleFilterChange('Active')}
          >
            Active
          </button>
          <button
            className={`py-2 px-4 rounded-md ${selectedFilter === 'Archived' ? 'bg-black text-white' : 'bg-gray-200'}`}
            onClick={() => handleFilterChange('Archived')}
          >
            Archived
          </button>
        </div>
    
        <div>
          <button
            className="bg-gray-200 py-2 px-4 rounded-md transition-colors duration-200 hover:bg-black hover:text-white"
            onClick={() => navigate('/admin/addproducts')}
          >
           Add New Product
          </button>
        </div>
      </div>

      {/* Inventory Table */}
      <table className="min-w-full border border-gray-200 text-left">
        <thead className="bg-gray-100">
          <tr>
            <th className="p-4 border-b">
              <input type="checkbox" className="form-checkbox" />
            </th>
            <th className="p-4 border-b">Product</th>
            <th className="p-4 border-b">Category</th>
            <th className="p-4 border-b">Product ID</th>
            <th className="p-4 border-b">Inventory</th>
            <th className="p-4 border-b">Status</th>
            <th className="p-4 border-b">Price</th>
            <th className="p-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredProducts.map((product) => (
            <tr key={product._id} className="border-b">
              <td className="p-4">
                <input type="checkbox" className="form-checkbox" />
              </td>
              <td className="p-4">{product.name}</td>
              <td className="p-4">{product.category}</td>
              <td className="p-4">{product._id}</td>
              <td className="p-4">{product.quantity}</td>
              <td className="p-4">{product.isArchived ? 'Archived' : 'Active'}</td>
              <td className="p-4">${product.price}</td>
              <td className="p-4 relative">
                <button
                  className="text-gray-700 hover:text-black"
                  onClick={() => handleActionMenuToggle(product._id)}
                >
                  &#x22EE;
                </button>
                {actionMenu === product._id && (
                  <div className="absolute right-82 mt-1 w-32 bg-white border rounded shadow-lg z-10">
                   <button
  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 w-full text-left"
  onClick={() => navigate(`/admin/editproduct/${product._id}`)}
>
  Edit Product
</button>
<button
  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 w-full text-left"
  onClick={() => navigate(`/admin/addinventory/${product._id}`)}
>
  Add to Inventory
</button>

                    <button
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-red-200 hover:text-red-800 w-full text-left"
                      onClick={() => confirmDelete(product)}
                    >
                      Delete Product
                    </button>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Confirmation Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded p-6 shadow-lg">
            <h2 className="text-lg font-bold mb-4">Are you sure you want to delete "{productToDelete?.name}"?</h2>
            <div className="flex justify-end">
              <button
                className="bg-gray-200 px-4 py-2 rounded-md mr-2"
                onClick={handleModalClose}
              >
                No
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-md"
                onClick={() => handleDelete(productToDelete._id)}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Products;
