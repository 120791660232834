import React from "react";
import { motion } from "framer-motion";

const HomeImages = () => {
  return (
    <div className="p-4 space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:mb-10">
        <div className="relative overflow-hidden">
          <motion.img
            src="https://res.cloudinary.com/deofmlywm/image/upload/v1730880395/IMG_8449_hopqyk.webp"
            alt="Fashion Look 1"
            width={500}
            height={500}
            loading="lazy"
            className="w-full h-auto object-cover transition-transform duration-500"
            whileInView={{ scale: 1.1, opacity: 1 }} // Scale up and fade in when in view
            initial={{ scale: 2, opacity: 0 }} // Start at normal size and fully transparent
            exit={{ scale: 1, opacity: 0 }} // Scale down and fade out when out of view
            transition={{ duration: 0.5 }}
          />
        </div>

        <div className="relative overflow-hidden">
          <motion.img
            src="https://res.cloudinary.com/deofmlywm/image/upload/v1731321724/bw_p5py5g.webp"
            alt="Fashion Look 2"
            width={500}
            height={500}
            loading="lazy"
            className="w-full h-auto object-cover transition-transform duration-500"
            whileInView={{ scale: 1.1, opacity: 1 }} // Scale up and fade in when in view
            initial={{ scale: 2, opacity: 0 }} // Start at normal size and fully transparent
            exit={{ scale: 1, opacity: 0 }} // Scale down and fade out when out of view
            transition={{ duration: 0.5 }}
          />
        </div>
      </div>

      <div className="text-center py-10 lg:py-20">
        <a
          href="/collection"
          className="text-black tracking-widest text-lg lg:text-2xl"
        >
          EXPLORE THE FASH LOOKBOOK
        </a>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:mb-10">
        <div className="relative overflow-hidden">
          <motion.img
            src="https://res.cloudinary.com/deofmlywm/image/upload/v1730880394/IMG_8720_zkkdzl.webp"
            alt="Fashion Look 3"
            width={500}
            height={500}
            loading="lazy"
            className="w-full h-auto object-cover transition-transform duration-500"
            whileInView={{ scale: 1.1, opacity: 1 }} // Scale up and fade in when in view
            initial={{ scale: 2, opacity: 0 }} // Start at normal size and fully transparent
            exit={{ scale: 1, opacity: 0 }} // Scale down and fade out when out of view
            transition={{ duration: 0.5 }}
          />
        </div>

        <div className="relative overflow-hidden">
          <motion.img
            src="https://res.cloudinary.com/deofmlywm/image/upload/v1730880395/IMG_8402_oawvzr.webp"
            alt="Fashion Look 4"
            width={500}
            height={500}
            loading="lazy"
            className="w-full h-auto object-cover transition-transform duration-500"
            whileInView={{ scale: 1.1, opacity: 1 }} // Scale up and fade in when in view
            initial={{ scale: 2, opacity: 0 }} // Start at normal size and fully transparent
            exit={{ scale: 1, opacity: 0 }} // Scale down and fade out when out of view
            transition={{ duration: 0.5 }}
          />
        </div>
      </div>
    </div>
  );
};

export default HomeImages;
