// src/pages/Faq.js
import React from "react";
import FaqItem from "./FaqItem";
import { faqData } from './faqData'

const Faq = () => {
  return (
    <div className="max-w-2xl mx-auto px-4 py-8">
            <h1 className="text-lg lg:text-3xl text-center mb-10 tracking-widest">FREQUENTYLY ASKED QUESTIONS</h1>
            <div>
        {faqData.map((faq, index) => (
          <FaqItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

export default Faq;
