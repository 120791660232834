// src/components/ReturnsAndRefunds.js
import React from "react";
import { returnsRefundsData } from "./returnsRefundsData";

const ReturnsAndRefunds = () => {
  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
   <h1 className="text-lg lg:text-3xl text-center mb-10 tracking-widest">RETURN & REFUND POLICY</h1>
      <p className="text-gray-600 mb-6">
        We want you to be fully satisfied with your purchase! If for any reason you're not happy with your order, please review our return and refund policy below.
      </p>
      <div>
        {returnsRefundsData.map((item, index) => (
          <div key={index} className="mb-6">
            <h2 className="text-xl font-semibold mb-2">{item.title}</h2>
            <p className="text-gray-600">{item.details}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReturnsAndRefunds;
