// src/data/internationalChargesData.js
export const internationalChargesData = [
    {
      title: "International Shipping Costs",
      details: "Shipping fees for international orders vary by region and package weight. You can view the estimated shipping cost at checkout. Please note that these fees do not include customs duties or taxes, which are charged by the destination country."
    },
    {
      title: "Customs Duties & Taxes",
      details: "International orders may be subject to customs duties and taxes, depending on the destination country. These fees are the responsibility of the customer and are not included in the order total. Please check with your local customs office for more details."
    },
    {
      title: "Estimated Delivery Times",
      details: "Delivery times for international orders vary by location. Generally, it takes 7-14 business days, but please allow for additional time during peak seasons or due to customs delays."
    },
    {
      title: "Tracking International Orders",
      details: "Once your order is shipped, a tracking number will be provided. Please use this number to track your order on our website or the carrier's tracking page for the latest updates."
    },
    {
      title: "International Return Policy",
      details: "Returns are accepted for international orders within 30 days of purchase. Customers are responsible for return shipping fees, and we recommend using a trackable shipping service for safe return. For more details, please review our general Return Policy."
    },
    {
      title: "Restricted Shipping Regions",
      details: "Due to shipping regulations, we currently do not ship to certain countries. If your country is not available during checkout, unfortunately, we cannot fulfill orders to that location at this time."
    },
  ];
  