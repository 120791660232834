import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

const AddInventory = () => {
  const { id: paramId } = useParams(); // The ID parameter passed from the route
  const navigate = useNavigate();
  const location = useLocation();

  // Use the id from the URL or fallback to the id from location state
  const initialId = paramId || location.state?.id;

  const [InventoryDetails, setInventoryDetails] = useState({
    _id: initialId || '', // Initialize with the id from URL or location state
    quantity: '', // Initialize quantity as an empty string
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    if (initialId) {
      const fetchInventoryDetails = async () => {
        try {
          const response = await fetch(`https://fash-backend-1.onrender.com/item/${initialId}`);
          const data = await response.json();
          setInventoryDetails({
            _id: data._id,
            quantity: '', // Set quantity to an empty string instead of the fetched value
          });
        } catch (error) {
          console.error('Error fetching Inventory details:', error);
          setError('Failed to fetch inventory details.');
        }
      };
      fetchInventoryDetails();
    } else {
      setError("No ID provided. Can't fetch inventory details.");
    }
  }, [initialId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Directly set the value from the input
    setInventoryDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(null);
    setError(null);

    if (!InventoryDetails._id) {
      setError("Inventory ID is required to update.");
      setLoading(false);
      return;
    }

    // Parse quantity as a number, or keep it empty if the input is empty
    const quantityValue = InventoryDetails.quantity !== '' ? parseInt(InventoryDetails.quantity, 10) : '';

    try {
      const response = await fetch(`https://fash-backend-1.onrender.com/item/stock/${InventoryDetails._id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          _id: InventoryDetails._id,
          quantity: quantityValue, // Send as a number or empty string
        }),
      });

      if (response.ok) {
        setSuccess('Inventory updated successfully!');
        setTimeout(() => navigate('/admin/product'), 1500);
      } else {
        setError('Failed to update Inventory.');
      }
    } catch (error) {
      console.error('Error updating Inventory:', error);
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-8 max-w-screen-3xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <button className="text-black flex items-center" onClick={() => window.history.back()}>
          &larr; Back to Product
        </button>
        <h1 className="text-2xl font-bold">Edit Inventory</h1>
      </div>

      {success && <div className="text-green-500 mb-4">{success}</div>}
      {error && <div className="text-red-500 mb-4">{error}</div>}

      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div>
            <div className="mb-6">
              <label className="block font-semibold">Inventory Id</label>
              <input
                type="text"
                name="id"
                value={InventoryDetails._id}
                className="w-full p-2 border rounded-md bg-gray-100"
                placeholder="Inventory ID"
                readOnly
              />
            </div>

            <div className="mb-6">
              <label className="block font-semibold">Inventory Quantity</label>
              <input
                type="number" // Change type to number for better input handling
                name="quantity"
                value={InventoryDetails.quantity}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                placeholder="Enter quantity"
              />
            </div>
          </div>
        </div>

        <button
          type="submit"
          className={`mt-4 bg-blue-500 text-white px-4 py-2 rounded ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={loading}
        >
          {loading ? 'Updating...' : 'Update Inventory'}
        </button>
      </form>
    </div>
  );
};

export default AddInventory;
