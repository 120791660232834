// src/components/SizeGuide.js
import React from "react";
import { sizeGuideData } from "./sizeGuideData";

const SizeGuide = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-lg md:text-2xl lg:text-3xl text-center mb-6 md:mb-10 tracking-widest uppercase">
        Size Guide
      </h1>
      <p className="text-gray-600 mb-4 md:mb-6 text-center text-sm md:text-base">
        Find the perfect fit with our detailed size guide for men's and women's shirts, trousers, and slides.
      </p>
      {sizeGuideData.map((category, index) => (
        <div key={index} className="mb-8">
          <h2 className="text-xl md:text-2xl font-semibold mb-3 md:mb-4">{category.category}</h2>
          <div className="overflow-x-auto">
            <table className="w-full border border-gray-300">
              <thead>
                <tr className="bg-gray-100">
                  <th className="py-2 px-2 md:px-4 border-b border-gray-300 text-left text-sm md:text-base">
                    Standard Size
                  </th>
                  <th className="py-2 px-2 md:px-4 border-b border-gray-300 text-left text-sm md:text-base">
                    US Size
                  </th>
                  <th className="py-2 px-2 md:px-4 border-b border-gray-300 text-left text-sm md:text-base">
                    EU Size
                  </th>
                  {category.sizes[0].chest && (
                    <th className="py-2 px-2 md:px-4 border-b border-gray-300 text-left text-sm md:text-base">
                      Chest
                    </th>
                  )}
                  {category.sizes[0].waist && (
                    <th className="py-2 px-2 md:px-4 border-b border-gray-300 text-left text-sm md:text-base">
                      Waist
                    </th>
                  )}
                  {category.sizes[0].length && (
                    <th className="py-2 px-2 md:px-4 border-b border-gray-300 text-left text-sm md:text-base">
                      Length
                    </th>
                  )}
                  {category.sizes[0].footLength && (
                    <th className="py-2 px-2 md:px-4 border-b border-gray-300 text-left text-sm md:text-base">
                      Foot Length
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {category.sizes.map((size, idx) => (
                  <tr key={idx} className="border-b border-gray-200">
                    <td className="py-2 px-2 md:px-4 text-sm">{size.size}</td>
                    <td className="py-2 px-2 md:px-4 text-sm">{size.usSize}</td>
                    <td className="py-2 px-2 md:px-4 text-sm">{size.euSize}</td>
                    {size.chest && <td className="py-2 px-2 md:px-4 text-sm">{size.chest}</td>}
                    {size.waist && <td className="py-2 px-2 md:px-4 text-sm">{size.waist}</td>}
                    {size.length && <td className="py-2 px-2 md:px-4 text-sm">{size.length}</td>}
                    {size.footLength && <td className="py-2 px-2 md:px-4 text-sm">{size.footLength}</td>}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SizeGuide;
