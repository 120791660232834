import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Collection = () => {
  const [collections, setCollections] = useState([]);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const response = await fetch("https://fash-backend-1.onrender.com/item/collection");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        // Map the API data to your required format
        const formattedCollections = data.map((collection) => ({
          title: collection.name,
          description: collection.des,
          link: `/collection/${collection._id}`, // Ensure _id is present in your data
          imageUrl: collection.img_Url, // Ensure img_Url is present in your data
        }));
        setCollections(formattedCollections);
      } catch (error) {
        console.error("Failed to fetch collections:", error);
      }
    };

    fetchCollections();
  }, []);

  return (
    <div className="flex flex-col">
      <section className="py-12 bg-white flex-1 wrapper p-5 md:px-10">
        <h1 className="text-lg lg:text-3xl text-center mb-10 tracking-widest">
          COLLECTIONS BY FASH
        </h1>
        <div className="max-w-[1440px] mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 px-4 md:px-0">
          {collections.map((collection, index) => (
            <div key={index} className="flex flex-col items-start text-start">
            <div
      className={`w-full h-96 bg-center mb-3 ${isLargeScreen ? "bg-cover" : "bg-contain"}`}
      style={{ backgroundImage: `url(${collection.imageUrl})` }}
    ></div>
              <p className="text-lg mb-4 font-bold">
                {collection.title}
              </p>
              <p className="text-sm text-gray-600 mb-4 text-justify">
  {collection.description.length > 100 ? `${collection.description.slice(0, 100)}...` : collection.description}
</p>

              <Link
                to={collection.link} // Change here
                className="inline-block px-6 py-2 bg-black text-white text-xs uppercase hover:bg-gray-800 transition tracking-widest"
              >
                Read More
              </Link>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Collection;
