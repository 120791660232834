import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';



const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 10; // Orders per page
  const [isEmailModalOpen, setEmailModalOpen] = useState(false);
  const [isOTPModalOpen, setOTPModalOpen] = useState(false);
  const [isRefundOpen, setRefundOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  // const [dropdownVisible, setDropdownVisible] = useState(null);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [refundPercent, setRefundPercent] = useState(0);
  const [refundAmount, setRefundAmount] = useState(0);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isOTPCorrect, setIsOTPCorrect] = useState(true); // New state for OTP correctness



  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get('https://fash-backend-1.onrender.com/order');
        const sortedOrders = response.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        ); // Sort orders by latest
        setOrders(sortedOrders);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };
    fetchOrders();
  }, []);
  // const handleDropdownClick = (orderId) => {
  //   setDropdownVisible(dropdownVisible === orderId ? null : orderId);
  // };

  const handleRefundClick = (order) => {
    setSelectedOrder(order);
    setEmailModalOpen(true);
    // setDropdownVisible(null);
  };

  const sendOTP = async () => {
    try {
      await axios.post('https://fash-backend-1.onrender.com/au/trigger', { email });
      setEmailModalOpen(false);
      setOTPModalOpen(true);
      console.log('OTP sent');
    } catch (error) {
      console.error('Error sending OTP:', error);
    }
  };

  const resendOTP = async () => {
    try {
      await axios.post('https://fash-backend-1.onrender.com/au/refresh', { email });
      console.log('OTP resent');
    } catch (error) {
      console.error('Error resending OTP:', error);
    }
  };

  const verifyOTP = async () => {
    try {
      console.log('Verifying OTP with:', { email, otp });
  
      const response = await axios.post('https://fash-backend-1.onrender.com/admin/sign-in', {
        email,
        otp,
      });
  
      if (response.data.status) {
        localStorage.setItem("adminToken", response.data.token); // Store token
        setOTPModalOpen(false);  // Close OTP modal
        setRefundOpen(true);     // Open refund modal
        console.log('Sign-in successful. Refund modal opened.');
        setIsOTPCorrect(true);   // Reset OTP correctness state
      } else {
        console.error('Invalid OTP:', response.data.message || 'No message provided');
        setIsOTPCorrect(false);  // Set OTP as incorrect
      }
    } catch (error) {
      console.error('Error verifying OTP:', error.response ? error.response.data : error.message);
      setIsOTPCorrect(false); // Set OTP as incorrect if error occurs
    }
  };
  

  const calculateRefundAmount = (percent) => {
    const amount = (selectedOrder.amount * percent) / 100;
    setRefundAmount(amount);
    console.log('Calculated refund amount:', amount); // Debugging log
  };
  

  const handleSubmitRefund = async () => {
    const refundData = {
      reference: selectedOrder.reference,
      amount: refundAmount,
    };
  
    console.log('Refund Data:', refundData); // Debugging log
  
    try {
      await axios.post('https://fash-backend-1.onrender.com/refund', refundData);
      console.log('Refund processed');
      setRefundOpen(false);
      setSuccessModalOpen(true);
    } catch (error) {
      console.error('Error processing refund:', error);
    }
  };
  

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedOrders([]);
    } else {
      setSelectedOrders(orders.map((order) => order._id));
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleSelectOrder = (orderId) => {
    if (selectedOrders.includes(orderId)) {
      setSelectedOrders(selectedOrders.filter((id) => id !== orderId));
    } else {
      setSelectedOrders([...selectedOrders, orderId]);
    }
  };
  const handleExport = () => {
    const dataToExport = orders.filter((order) => selectedOrders.includes(order._id));
    const formattedData = dataToExport.map((order) => ({
      Order: `#${order._id}`,
      Customer: order.email,
      Amount: `$${order.amount / 100}`,
      PaymentStatus: order.orderStatus,
      ShippingAddress: `${order.shippingAddress.street}, ${order.shippingAddress.city}, ${order.shippingAddress.state}, ${order.shippingAddress.postalCode}, ${order.shippingAddress.country}`,
      OrderDate: new Date(order.createdAt).toLocaleDateString(),
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders');
    XLSX.writeFile(workbook, 'SelectedOrders.xlsx');
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);
  const totalPages = Math.ceil(orders.length / ordersPerPage);

  return (
    <div className="p-8 max-w-screen-xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Orders</h1>
        <div className="flex gap-2">
          <button onClick={handleExport} className="bg-white text-black border py-2 px-4 rounded-md hover:bg-black hover:text-white">Export as Excel</button>
        </div>
      </div>

      <table className="min-w-full border border-gray-200 text-xs">
        <thead>
          <tr className="bg-gray-100">
            <th className="p-4">
              <input
                type="checkbox"
                checked={isAllSelected}
                onChange={handleSelectAll}
              />
            </th>
            <th className="text-left p-4">Reference ID</th>
            <th className="text-left p-4">Customer Name</th>
            <th className="text-left p-4">Amount</th>
            <th className="text-left p-4">Payment Status</th>
            <th className="text-left p-4">Shipping Address</th>
            <th className="text-left p-4">Order Date</th>
            <th className="p-4">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentOrders.length > 0 ? (
            currentOrders.map((order, idx) => (
              <tr key={order.reference} className="border-b">
                <td className="p-4">
                  <input
                    type="checkbox"
                    checked={selectedOrders.includes(order.reference)}
                    onChange={() => handleSelectOrder(order.reference)}
                  />
                </td>
                <td className="p-4">{order.reference}</td>
                <td className="p-4">{order.email}</td>
                <td className="p-4">${order.amount}</td>
                <td className="p-4">{order.orderStatus}</td>
                <td className="p-4">
                  {order.shippingAddress.street}, {order.shippingAddress.city}, {order.shippingAddress.state}, {order.shippingAddress.postalCode}, {order.shippingAddress.country}
                </td>
                <td className="p-4">{new Date(order.createdAt).toLocaleDateString()}</td>
                <td className="p-4">
                  <button onClick={() => handleRefundClick(order)} className="text-black bg-white border py-2 px-4 rounded-md hover:bg-black hover:text-white">Refund</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="text-center p-4">No orders found</td>
            </tr>
          )}
        </tbody>
      </table>

        {/* Email Input Modal */}
  {isEmailModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-lg font-bold mb-4">Enter Email Address</h2>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-2 border mb-4"
              placeholder="Enter email address"
            />
            <div className="flex gap-4">
              <button className="bg-green-500 text-white py-2 px-4 rounded-md" onClick={sendOTP}>
                Send OTP
              </button>
              <button className="bg-red-500 text-white py-2 px-4 rounded-md" onClick={() => setEmailModalOpen(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

  {/* OTP Modal */}
  {isOTPModalOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white p-8 rounded-lg shadow-lg">
      <h2 className="text-lg font-bold mb-4">Enter OTP</h2>
      <input
        type="text"
        value={otp}
        onChange={(e) => setOtp(e.target.value)}
        className="w-full p-2 border mb-4"
        placeholder="Enter OTP"
      />
      {!isOTPCorrect && (
        <p className="text-red-500 mb-4">Wrong OTP. Please try again.</p>
      )}
      <div className="flex gap-4">
        <button className="bg-green-500 text-white py-2 px-4 rounded-md" onClick={verifyOTP}>
          Verify OTP
        </button>
        <button className="bg-blue-500 text-white py-2 px-4 rounded-md" onClick={resendOTP}>
          Resend OTP
        </button>
        <button className="bg-red-500 text-white py-2 px-4 rounded-md" onClick={() => setOTPModalOpen(false)}>
          Cancel
        </button>
      </div>
    </div>
  </div>
)}



      {/* Refund Modal */}
      {isRefundOpen && selectedOrder && (
        <div className="fixed top-0 right-0 w-1/3 h-full bg-white shadow-lg p-8 z-50">
          <button className="text-xl font-bold" onClick={() => setRefundOpen(false)}>
            &times;
          </button>
          <h2 className="text-lg font-bold mb-4">Refund Details</h2>
          <div className="mb-4">
            <p className="text-gray-600">Order ID: {selectedOrder._id}</p>
            <p className="flex items-center">
              <span className="mr-2">👤</span>{selectedOrder.email}
            </p>
            <p className="flex items-center">
              <span className="mr-2">📞</span>{selectedOrder.phone_Number}
            </p>
          </div>
          <div className="mb-4">
            <p>Reference ID</p>
            <input type="text" className="w-full p-2 border" placeholder="Enter Reference Id" disabled value={selectedOrder.reference} />
          </div>
          <div className="mb-4 flex justify-between">
            <p>Order total</p>
            <p>% to be refunded</p>
          </div>
          <div className="mb-4 flex justify-between">
            <input
              type="text"
              className="p-2 border w-1/2"
              value={`$${selectedOrder.amount}.00`}
              disabled
            />
            <input
              type="number"
              className="p-2 border w-1/3"
              placeholder="Enter %"
              value={refundPercent}
              onChange={(e) => {
                const percent = e.target.value;
                setRefundPercent(percent);
                calculateRefundAmount(percent);
              }}
            />
          </div>
          <div className="mb-4 text-center">
            <p>The amount is ${refundAmount.toFixed(2)}</p> {/* Display refund amount */}
          </div>
          <div className="flex gap-4">
            <button
              className="bg-green-500 text-white py-2 px-4 rounded-md"
              onClick={handleSubmitRefund}
            >
              Submit
            </button>
            <button
              className="bg-red-500 text-white py-2 px-4 rounded-md"
              onClick={() => setRefundOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      

      {/* Success Modal */}
      {isSuccessModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-lg font-bold mb-4">Refund Successful</h2>
            <p className="text-green-500">The refund has been successfully processed.</p>
            <button
              className="bg-black text-white py-2 px-4 rounded-md mt-4"
              onClick={() => setSuccessModalOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Pagination Controls */}
      <div className="flex justify-center my-4">
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i}
            onClick={() => handlePageChange(i + 1)}
            className={`px-3 py-1 mx-1 rounded ${currentPage === i + 1 ? 'bg-black text-white' : 'bg-gray-200 text-black'}`}
          >
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Orders;
