// src/components/CorporateResponsibility.js
import React from "react";

const CorporateResponsibility = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
           <h1 className="text-lg lg:text-3xl text-center mb-10 tracking-widest uppercase">Corporate Responsibility</h1>

      <p className="mb-4">
        At Fash Attire, we believe in conducting our business in a socially responsible and ethical manner. Our commitment to corporate responsibility encompasses our environmental sustainability efforts, ethical sourcing practices, and community engagement initiatives.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">1. Environmental Sustainability</h2>
      <p className="mb-4">
        We are dedicated to reducing our environmental impact through sustainable practices. This includes:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li className="mb-2">Using eco-friendly materials in our clothing and packaging.</li>
        <li className="mb-2">Minimizing waste by implementing recycling programs.</li>
        <li className="mb-2">Striving to reduce our carbon footprint through efficient logistics and distribution practices.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">2. Ethical Sourcing</h2>
      <p className="mb-4">
        We are committed to ethical sourcing and ensuring fair labor practices throughout our supply chain. Our initiatives include:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li className="mb-2">Partnering with suppliers who adhere to fair labor standards.</li>
        <li className="mb-2">Ensuring safe working conditions and fair wages for all workers involved in the production of our products.</li>
        <li className="mb-2">Regular audits of our suppliers to maintain compliance with our ethical standards.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">3. Community Engagement</h2>
      <p className="mb-4">
        We are passionate about giving back to our community. Our community engagement initiatives include:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li className="mb-2">Supporting local charities and organizations through donations and partnerships.</li>
        <li className="mb-2">Encouraging employee volunteerism and community service.</li>
        <li className="mb-2">Promoting education and awareness around sustainability and ethical practices in fashion.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">4. Commitment to Transparency</h2>
      <p className="mb-4">
        We believe in transparency and accountability. We strive to communicate openly about our practices, policies, and progress towards our sustainability goals. Our stakeholders can expect regular updates on our corporate responsibility initiatives.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">5. Looking Ahead</h2>
      <p className="mb-4">
        At [Your Brand Name], our commitment to corporate responsibility is ongoing. We continuously seek to improve our practices and contribute positively to society and the environment. We welcome feedback and suggestions from our customers and stakeholders as we strive to make a meaningful impact.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Contact Us</h2>
      <p>
        For more information about our corporate responsibility initiatives, please contact us at:
      </p><br />
      <p>Fash Attire</p>
      <p>info.fashattire@gmail.com</p>
    </div>
  );
};

export default CorporateResponsibility;
