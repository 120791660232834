// src/components/InternationalCharges.js
import React from "react";
import { internationalChargesData } from "./internationalChargesData";

const InternationalCharges = () => {
  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
        <h1 className="text-lg lg:text-3xl text-center mb-10 tracking-widest uppercase">International Charges & Policies</h1>
      <p className="text-gray-600 mb-6">
        We are pleased to offer international shipping! Please review the following information to understand the charges and policies that may apply to international orders.
      </p>
      <div>
        {internationalChargesData.map((item, index) => (
          <div key={index} className="mb-6">
            <h2 className="text-xl font-semibold mb-2">{item.title}</h2>
            <p className="text-gray-600 whitespace-pre-line">{item.details}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InternationalCharges;
