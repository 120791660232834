import React, { useState } from 'react';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        orderId: '',
        subject: '',
        message: ''
    });
    
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage('');  // Reset success message
        setErrorMessage('');    // Reset error message

        // Validate fields
        if (!formData.firstName || !formData.lastName || !formData.email || !formData.subject || !formData.message) {
            setErrorMessage('Please fill in all required fields.');
            return;
        }

        // Validate email
        if (!formData.email.includes('@')) {
            setErrorMessage('Please enter a valid email address.');
            return;
        }

        try {
            const response = await fetch('https://fash-backend-1.onrender.com/au/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log('Response Data: ', data);
            setSuccessMessage('Your message has been sent successfully!');  // Set success message
            
            // Remove success message after 3 seconds
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);
            
            // Optionally reset the form
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                orderId: '',
                subject: '',
                message: ''
            });
        } catch (error) {
            console.error('Error submitting form: ', error);
            setErrorMessage('There was an error submitting your message. Please try again later.');
        }
    };

    return (
        <section className="py-12">
            {successMessage && (
                <div className="bg-green-500 text-white p-4 text-center fixed top-0 left-0 right-0 z-50">
                    {successMessage}
                </div>
            )}
            <h1 className="text-lg lg:text-2xl font-bold text-center mb-10 tracking-widest">CONTACT</h1>
            {/* <p className="text-center text-sm mb-8 px-4">
                Please only use this form for general inquiries. Any questions relating to an order should be sent to
                <a href="mailto:info.fashattire@gmail.com" className="underline ml-1">info.fashattire@gmail.com</a>. Ensure you provide your order number.
            </p> */}
            {errorMessage && <p className="text-red-600 text-center">{errorMessage}</p>}
            <form onSubmit={handleSubmit} className="max-w-2xl mx-auto space-y-6 px-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <input
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={handleChange}
                        className="border border-gray-300 p-2 w-full"
                        required
                    />
                    <input
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={formData.lastName}
                        onChange={handleChange}
                        className="border border-gray-300 p-3 w-full"
                        required
                    />
                </div>
                <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    className="border border-gray-300 p-3 w-full"
                    required
                />
                <input
                    type="text"
                    name="orderId"
                    placeholder="Order Number"
                    value={formData.orderId}
                    onChange={handleChange}
                    className="border border-gray-300 p-3 w-full"
                />
                <input
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    value={formData.subject}
                    onChange={handleChange}
                    className="border border-gray-300 p-3 w-full"
                    required
                />
                <textarea
                    name="message"
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleChange}
                    className="border border-gray-300 p-3 w-full h-32"
                    required
                />
                <button
                    type="submit"
                    className="block w-full bg-black text-white py-3 uppercase tracking-widest hover:bg-gray-800 transition"
                >
                    Send Message
                </button>
            </form>
        </section>
    );
};

export default ContactForm;
