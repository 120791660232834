import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const EditProducts = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [productDetails, setProductDetails] = useState({
    name: '',
    price: '',
    category: '',
    sex: '',
    quantity: '',
    _collection: '',
    status: 'Active',
    sizes: [],
    colors: [],
    detail: '',
    description: '',
    img_Url: '',
    hover_Url: '',
    img_Arr_Url: [],
    featured: false,
    isArchived: false
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await fetch(`https://fash-backend-1.onrender.com/item/${id}`);
        const data = await response.json();
        setProductDetails({
          name: data.name,
          sex: data.sex,
          _collection: data._collection,
          category: data.category,
          price: data.price,
          colors: data.color,
          sizes: data.size,
          description: data.description,
          featured: productDetails.featured,
          isArchived: productDetails.isArchived,
          detail: data.detail,
          img_Url: data.img_Url || '',
          hover_Url: productDetails.hover_Url,
          img_Arr_Url: data.img_Arr_Url || [],
        });
      } catch (error) {
        console.error('Error fetching Product details:', error);
      }
    };

    fetchProductDetails();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleSizesChange = (size) => {
    setProductDetails((prev) => ({
      ...prev,
      sizes: prev.sizes.includes(size)
        ? prev.sizes.filter((s) => s !== size)
        : [...prev.sizes, size],
    }));
  };

  const handleColorsChange = (color) => {
    setProductDetails((prev) => ({
      ...prev,
      colors: prev.colors.includes(color)
        ? prev.colors.filter((c) => c !== color)
        : [...prev.colors, color],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(null);
    setError(null);

    try {
      const response = await fetch(`https://fash-backend-1.onrender.com/item/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...productDetails,
          color: productDetails.colors,
          size: productDetails.sizes,
        }),
      });

      if (response.ok) {
        setSuccess('Product updated successfully!');
        setTimeout(() => navigate('/admin/product'), 1500);
      } else {
        setError('Failed to update product.');
      }
    } catch (error) {
      console.error('Error updating product:', error);
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setProductDetails((prev) => ({ ...prev, img_Url: reader.result }));
      reader.readAsDataURL(file);
    }
  };

  const handleHoverImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setProductDetails((prev) => ({ ...prev, hover_Url: reader.result }));
      reader.readAsDataURL(file);
    }
  };

  const handleAdditionalImagesChange = (e) => {
    const files = Array.from(e.target.files);
    const urls = files.map((file) => URL.createObjectURL(file));
    setProductDetails((prev) => ({ ...prev, img_Arr_Url: [...prev.img_Arr_Url, ...urls] }));
  };

  const removeImage = (index) => {
    setProductDetails((prev) => ({
      ...prev,
      img_Arr_Url: prev.img_Arr_Url.filter((_, i) => i !== index),
    }));
  };

  return (
    <div className="p-8 max-w-screen-3xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <button className="text-black flex items-center" onClick={() => window.history.back()}>
          &larr; Back to Product
        </button>
        <h1 className="text-2xl font-bold">Edit Product</h1>
      </div>

      {success && <div className="text-green-500 mb-4">{success}</div>}
      {error && <div className="text-red-500 mb-4">{error}</div>}

      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div>
            {/* Product Name */}
            <div className="mb-6">
              <label className="block font-semibold">Product Name</label>
              <input
                type="text"
                name="name"
                value={productDetails.name}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                placeholder="Enter name"
              />
            </div>

            {/* Product Price */}
            <div className="mb-6">
              <label className="block font-semibold">Product Price</label>
              <input
                type="text"
                name="price"
                value={productDetails.price}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                placeholder="Enter price"
              />
            </div>

            {/* Product Quantity */}
            <div className="mb-6">
              <label className="block font-semibold">Product Quantity</label>
              <input
                type="text"
                name="quantity"
                value={productDetails.quantity}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                placeholder="Enter Quantity"
              />
            </div>

            {/* Product Category */}
            <div className="mb-6">
              <label className="block font-semibold">Product Category</label>
              <input
                type="text"
                name="category"
                value={productDetails.category}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                placeholder="Enter category"
              />
            </div>

            {/* Sex */}
            <div className="mb-6">
              <label className="block font-semibold">Sex</label>
              <select
                name="sex"
                value={productDetails.sex}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
              >
                <option value="">Select sex</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>


            <div className="mb-6">
          <label className="block font-semibold">Featured</label>
          <select
            name="featured"
            value={productDetails.featured}
            onChange={handleInputChange}
            className="w-full p-2 border rounded-md"
          >
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </div>

        {/* Is Archived Field */}
        <div className="mb-6">
          <label className="block font-semibold">Is Archived</label>
          <select
            name="isArchived"
            value={productDetails.isArchived}
            onChange={handleInputChange}
            className="w-full p-2 border rounded-md"
          >
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </div>

            {/* Sizes */}
            <div className="mb-6">
              <label className="block font-semibold">Sizes</label>
              <div className="grid grid-cols-6 gap-2">
                {['XS', 'S', 'M', 'L', 'XL', 'XXL'].map((size) => (
                  <label key={size} className="flex items-center">
                    <input
                      type="checkbox"
                      checked={productDetails.sizes.includes(size)}
                      onChange={() => handleSizesChange(size)}
                    />
                    <span className="ml-2">{size}</span>
                  </label>
                ))}
              </div>
            </div>

            {/* Colors */}
            <div className="mb-6">
              <label className="block font-semibold">Colors</label>
              <div className="flex flex-wrap gap-2">
                {['Beige', 'Brown', 'Cream', 'Grey', 'Black', 'White'].map(
                  (color) => (
                    <button
                      key={color}
                      type="button"
                      className={`p-2 border rounded-md ${
                        productDetails.colors.includes(color) ? 'bg-gray-300' : 'bg-white'
                      }`}
                      onClick={() => handleColorsChange(color)}
                    >
                      {color}
                    </button>
                  )
                )}
              </div>
            </div>

            {/* Product Detail */}
            {/* <div className="mb-6">
              <label className="block font-semibold">Product Detail</label>
              <textarea
                name="detail"
                value={productDetails.detail}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                placeholder="Enter product detail"
                rows="4"
              />
            </div> */}

            {/* Product Description */}
            <div className="mb-6">
              <label className="block font-semibold">Product Description</label>
              <textarea
                name="description"
                value={productDetails.description}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                placeholder="Enter product description"
                rows="4"
              />
            </div>

            {/* Featured Product */}
            <div className="mb-6">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={productDetails.featured}
                  onChange={(e) => setProductDetails((prev) => ({ ...prev, featured: e.target.checked }))}
                  className="mr-2"
                />
                Featured Product
              </label>
            </div>
          </div>

          {/* Right Side - Images */}
          <div>
          <div>
              <label className="block font-semibold mb-2">Cover Image</label>
              <input type="file" onChange={handleCoverImageChange} className="w-full" />
              {productDetails.img_Url && <img src={productDetails.img_Url} alt="Cover" className="mt-2 w-full h-40 object-cover rounded-md shadow-sm" />}
            </div>

            <div>
              <label className="block font-semibold mb-2">Hover Image</label>
              <input type="file" onChange={handleHoverImageChange} className="w-full" />
              {productDetails.hover_Url && <img src={productDetails.hover_Url} alt="Hover" className="mt-2 w-full h-40 object-cover rounded-md shadow-sm" />}
            </div>


             <div>
              <label className="block font-semibold mb-2">Additional Images</label>
              <input type="file" multiple onChange={handleAdditionalImagesChange} className="w-full" />
              <div className="grid grid-cols-3 gap-4 mt-4">
                {productDetails.img_Arr_Url.map((url, index) => (
                  <div key={index} className="relative">
                    <img src={url} alt={`Additional ${index + 1}`} className="w-full h-32 object-cover rounded-md shadow" />
                    <button
                      onClick={() => removeImage(index)}
                      className="absolute top-0 right-0 m-1 text-white bg-red-600 rounded-full p-1 hover:bg-red-800 transition"
                      title="Remove Image"
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <button
          type="submit"
          className={`mt-4 bg-blue-500 text-white px-4 py-2 rounded ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={loading}
        >
          {loading ? 'Updating...' : 'Update Product'}
        </button>
      </form>
    </div>
  );
};

export default EditProducts;
