// src/data/returnsRefundsData.js
export const returnsRefundsData = [
    {
      title: "Return Eligibility",
      details: "Items can be returned within 30 days of purchase as long as they are unworn, unwashed, and in their original packaging with tags attached. Final sale items are not eligible for return."
    },
    {
      title: "Return Process",
      details: "Contact info.fashattire@gmail.com  and we will get back to you between 3-5 business days."
    },
    {
      title: "Refund Processing",
      details: "Once your return is received and inspected, we will process your refund within 5-7 business days. You’ll be notified via email when the refund has been issued."
    },
    {
      title: "Return Shipping Fees",
      details: "For domestic returns, a flat $5 return shipping fee will be deducted from your refund. International return shipping fees may vary based on location."
    },
    {
      title: "Exchanges",
      details: "At this time, we do not offer direct exchanges. If you'd like a different size or color, please place a new order and return the original item."
    },
    {
      title: "Damaged or Defective Items",
      details: "If your item arrives damaged or defective, please contact our support team within 7 days of receiving the item. We'll arrange for a replacement at no extra cost."
    },
  ];
  