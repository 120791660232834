import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Collection = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  // const [selectedProduct, setSelectedProduct] = useState(null);
  const [actionMenu, setActionMenu] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://fash-backend-1.onrender.com/item/collection');
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
    fetchProducts();
  }, []);

  const handleActionMenuToggle = (productId) => {
    setActionMenu((prev) => (prev === productId ? null : productId));
  };

  // const handleSeeMore = (product) => {
  //   setSelectedProduct(product);
  // };

  // const handleCloseDetails = () => {
  //   setSelectedProduct(null);
  // };

  const handleDelete = async (productId) => {
    try {
      const response = await fetch(`https://fash-backend-1.onrender.com/item/collection/${productId}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        setProducts(products.filter((product) => product._id !== productId));
        setShowModal(false);
      } else {
        console.error('Failed to delete product');
      }
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const confirmDelete = (product) => {
    setProductToDelete(product);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setProductToDelete(null);
  };

  return (
    <div className="relative p-8">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Collections</h1>
        <button
          className="bg-gray-200 py-2 px-4 rounded-md transition-colors duration-200 hover:bg-black hover:text-white"
          onClick={() => navigate('/admin/addcollection')}
        >
          + Add Collection
        </button>
      </div>

      {/* Check if there are products and render accordingly */}
      {products.length === 0 ? (
        <div className="text-center text-gray-500 mt-4">
          No collection found
        </div>
      ) : (
        <table className="min-w-full border border-gray-200 text-left">
          <thead className="bg-gray-100">
            <tr>
              <th className="p-4 border-b">
                <input type="checkbox" className="form-checkbox" />
              </th>
              <th className="p-4 border-b">Collection ID</th>
              <th className="p-4 border-b">Collection Name</th>
              <th className="p-4 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product._id} className="border-b">
                <td className="p-4">
                  <input type="checkbox" className="form-checkbox" />
                </td>
                <td className="p-4">{product._id}</td>
                <td className="p-4">{product.name}</td>
                <td className="p-4 relative">
                  <button
                    className="text-gray-700 hover:text-black"
                    onClick={() => handleActionMenuToggle(product._id)}
                  >
                    &#x22EE;
                  </button>
                  {actionMenu === product._id && (
                    <div className="absolute right-82 mt-1 w-24 bg-white border rounded shadow-lg z-10">
                    <button
  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 w-full text-left"
  onClick={() => navigate(`/admin/editcollection/${product._id}`)}
>
  Edit
</button>

                      <button
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-red-200 hover:text-red-800 w-full text-left"
                        onClick={() => confirmDelete(product)}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Confirmation Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded p-6 shadow-lg">
            <h2 className="text-lg font-bold mb-4">Are you sure you want to delete "{productToDelete?.name}"?</h2>
            <div className="flex justify-end">
              <button
                className="bg-gray-200 px-4 py-2 rounded-md mr-2"
                onClick={handleModalClose}
              >
                No
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-md"
                onClick={() => handleDelete(productToDelete._id)}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Collection;
