import React from "react";
import { motion } from "framer-motion";

const AboutUs = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <h1 className="text-3xl text-center mb-7 tracking-widest">
        The origins of the company
      </h1>
      <p className="text-lg mb-6 text-center ">
        The Story of Fash Attire: A Journey of Elegance, Grace, and Integrity
      </p>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-4 lg:mb-10">
        <div className="relative overflow-hidden">
          <motion.img
            src="https://res.cloudinary.com/deofmlywm/image/upload/v1731328561/pexels-kelly-1179532-17290985_njfcyc.webp"
            alt="Fashion Look 1"
            width={300}
            height={300}
            loading="lazy"
            className="w-full h-auto object-cover transition-transform duration-500"
            whileInView={{ scale: 1.1, opacity: 1 }} // Scale up and fade in when in view
            initial={{ scale: 2, opacity: 0 }} // Start at normal size and fully transparent
            exit={{ scale: 1, opacity: 0 }} // Scale down and fade out when out of view
            transition={{ duration: 0.5 }}
          />
        </div>
      </div>

           <p className="text-justify leading-7 mt-6 mb-6 max-w-5xl mx-auto">

        My personal expedition of self-understanding has been underway for some
        time. Fash was born as an outlet for external representation of
        principles guiding me as I willfully take this journey of shifting
        destinations. Throughout university as I studied to become an engineer,
        and the early years afterward, I searched for meaning, often through
        trial and error, always coming back to certain truths about what I
        valued most. In hindsight, my university days, a phase filled with
        youthful freedom and its inevitable allure of indulgence, culminated in
        an awakening to what my role actually is in this lifetime. Blessed with
        the creative gene of imagination and untainted will to remain in
        exploration, one person stands out clearly on my journey, like a guiding
        lunar amidst an often-chaotic sky. She was refined, and possessed an
        innate grace that’s undoubtably rare and timeless. In an age where most
        of my peers encouraged instant gratification and hedonistic
        distractions, her quiet elegance grounded me. 
        
        <br /><br />
        
        When I first met Fash, we
        were both barely twenty, navigating classes, endless essays, and the
        familiar challenges of growing up. She had an effortless beauty, an air
        of refinement that drew others in. While others competed to be seen, she
        was always serene, almost as if she had already achieved the kind of
        internal poise the rest of us were scrambling to find. She soon became
        not only my close friend but also a figure of rare strength and a
        figurine for simplicity—someone who lived in a way that was true to her
        deepest values.     <br /><br />
        
         One evening, I was in a dilemma, contemplating choices
        that weren’t true to the person I wanted to be. The idea of quick wins,
        of chasing fleeting excitement, seemed tempting, and it was easy to
        justify them as “just part of being young.” she listened as I confided
        in her, not with judgment but with that calm understanding I admired so
        much. She told me a story from her own life, one where she had faced
        similar temptations, and she reminded me that true refinement was in
        knowing what to say no to one’s self despite overwhelming cravings. Her
        words struck me deeply, revealing an integrity that resonated beyond the
        noise of youthful desert.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-1 gap-4 lg:mb-10">
        <div className="relative overflow-hidden">
          <motion.img
            src="https://res.cloudinary.com/deofmlywm/image/upload/v1730892216/must_use_1_nka3oj.webp"
            alt="Fashion Look 1"
            width={500}
            height={500}
            loading="lazy"
            className="w-full h-auto object-cover transition-transform duration-500"
            whileInView={{ scale: 1.1, opacity: 1 }} // Scale up and fade in when in view
            initial={{ scale: 2, opacity: 0 }} // Start at normal size and fully transparent
            exit={{ scale: 1, opacity: 0 }} // Scale down and fade out when out of view
            transition={{ duration: 0.5 }}
          />
        </div>
      </div>

            <p className="text-justify leading-7 mt-6 mb-6 max-w-5xl mx-auto">

        From then on, I chose her to become a kind of moral compass for me. We
        would meet after class, talk for hours, and her soft wisdom influenced
        so much of who I would become. She had this beautiful ability to see
        simplicity in things, to strip away the superficial and hold onto what
        was real, good, and enduring. Even though she was a model, a part of the
        fashion world, she approached her work with a humility that transcended
        the surface glamour. Through her, I saw a side of fashion that was about
        more than just appearances—it was about grace, respect, and the strength
        to live by one’s values. That soul, more than anything, was what I
        wanted to capture with Fash. 
        
        <br /> <br />
        
        In her final year of university, we would
        often wander around the campus gardens, talking about our dreams. I
        began to realize that to build something meaningful, something lasting
        that would represent the purity of our ideations, I needed a foundation
        rooted in the values she embodied. This was when the first vision of
        Fash began to form—a line of clothing that didn’t chase trends or fast
        fame but embraced a kind of discreet luxury defined by simplicity,
        elegance, and authenticity. I wanted to create garments that would
        reflect the poise she carried, the modest confidence that had so deeply
        impacted me.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:mb-10">
        <div className="relative overflow-hidden">
          <motion.img
            src="https://res.cloudinary.com/deofmlywm/image/upload/v1730890488/pexels-anassfakhari-1458770_gpfcsu.webp"
            alt="Fashion Look 1"
            width={500}
            height={500}
            loading="lazy"
            className="w-full h-auto object-cover transition-transform duration-500"
            whileInView={{ scale: 1.1, opacity: 1 }} // Scale up and fade in when in view
            initial={{ scale: 2, opacity: 0 }} // Start at normal size and fully transparent
            exit={{ scale: 1, opacity: 0 }} // Scale down and fade out when out of view
            transition={{ duration: 0.5 }}
          />
        </div>

        <div className="relative overflow-hidden">
          <motion.img
            src="https://res.cloudinary.com/deofmlywm/image/upload/v1730890488/must_use_wear_taking_about_collection_xb4zse.webp"
            alt="Fashion Look 2"
            width={500}
            height={500}
            loading="lazy"
            className="w-full h-auto object-cover transition-transform duration-500"
            whileInView={{ scale: 1.1, opacity: 1 }} // Scale up and fade in when in view
            initial={{ scale: 2, opacity: 0 }} // Start at normal size and fully transparent
            exit={{ scale: 1, opacity: 0 }} // Scale down and fade out when out of view
            transition={{ duration: 0.5 }}
          />
        </div>
      </div>

            <p className="text-justify leading-7 mt-6 mb-6 max-w-5xl mx-auto">

        One afternoon, she accompanied me to a local fabric store. We spent
        hours there, feeling the textures, gaping at the understated beauty of
        natural fibers. She was noticing details I would miss and explaining why
        quirks in designs that others saw as errors represented value to her
        because they depicted human nature in its core truth which was in her
        words- “a state with enough room for continuous improvement”—the subtle
        shift in shade under different lighting, the way a fabric felt against
        the skin. It was in that store that we found a particular fabric, a
        soft, understated material that immediately felt like it could be part
        of something timeless. She turned to me with her eyes gleaming with
        encouragement, and said, “This could be something. Go with it.” That
        moment captured everything I would later hope Fash would stand for:
        timeless quality, subtle beauty, and a deep sense of grace.
        
        <br /> <br />
        
         As I built
        Fash, went around sourcing unique fabric to use for attires I’d
        designed, Fash’s presence was a constant reminder of what the brand
        should embody. Each piece is created to mirror the values she held
        close—elegance that’s inobtrusive, confidence that’s modest, and a
        luxury that doesn’t need to shout. Fash is not about creating clothes
        that merely impress; it’s about creating clothes that express. I wanted
        people who wore Fash attire to feel that same quiet strength, that
        dignity in knowing they are enough just as they are.     <br /> <br />
        
        
        Years later, Fash
        has grown, and her influence is stitched into every collection. When our
        customers wear Fash, they aren’t just wearing a garment; they’re
        stepping into a philosophy of simplicity, elegance, and veracity.
        They’re honoring a way of life that Fash helped me understand—one that
        doesn’t seek approval from the world but instead finds strength and
        purpose from within. I think back to our conversations often. I’d always
        share big ideas with her about what I wanted to do with myself in the
        world, everything I feared could stop their fruition, and the moments we
        shared when Fash was just an idea, a seed that hadn’t yet sprouted. Her
        poise, her wisdom, and her belief in me were the foundation upon which
        Fash attire was built. Today, Fash is more than a high-end luxury brand;
        it’s a tribute to a friendship that defined my path, to a woman who
        taught me that elegance is not just about how one looks but about how
        one lives. And when I see someone wearing Fash attire, I see a
        continuation of her light—a reminder that elegance, true elegance, is
        found in the strength of character, the simplicity of beauty, and the
        grace of a life well-lived.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-1 gap-4 lg:mb-10">
        <div className="relative overflow-hidden">
          <motion.img
            src="https://res.cloudinary.com/deofmlywm/image/upload/v1731339388/hgvkcjsbdvhkds_pnvxim.webp"
            alt="Fashion Look 1"
            width={500}
            height={500}
            loading="lazy"
            className="w-full h-auto object-cover transition-transform duration-500"
            whileInView={{ scale: 1.1, opacity: 1 }} // Scale up and fade in when in view
            initial={{ scale: 2, opacity: 0 }} // Start at normal size and fully transparent
            exit={{ scale: 1, opacity: 0 }} // Scale down and fade out when out of view
            transition={{ duration: 0.5 }}
          />
        </div>
      </div>

           <p className="text-justify leading-7 mt-6 mb-6 max-w-5xl mx-auto">

        As the launch of Fash’s first collection, EOG: Expression of Grace 2024,
        drew closer, I found myself reflecting on the profound meaning behind
        it, on the story of friendship and growth that inspired it. This
        collection is a tribute not only to the simplicity and refinement that
        grace itself embodies but also to what that grace can express through
        the harmony of elegance, form, and restraint—a quality my friend
        inspired me to see and seek in myself and in the world. Her sangfroid
        and presence set a bar, as if through her example, she illuminated that
        the true essence of grace is in clarity of purpose, in a life that
        embraces restraint but never restricts. I wanted each piece in this
        collection to echo that spirit of honor and humility, with the quiet
        confidence she possessed—a style that speaks with refined whispers, yet
        leaves an eternal impression. 
        
        <br /><br />
        
        The Expression of Grace collection is a
        philosophy in fabric. With the men’s line, the aim is to reflect the
        stoic calmness and disciplined essence she helped me cultivate in
        myself—a balance between strength and subtlety, between form and
        comfort. Each garment’s tailoring is meticulous, a testament to the idea
        that precision can elevate simplicity to sophistication. Meanwhile, the
        women's footgear resonates with her elegance: graceful, designed to feel
        effortless but with touches of refined detail that speak to
        sophistication without flamboyance. This collection, like her influence
        on me, celebrates restraint and the quiet power of intentional,
        deliberate choices.    <br /><br />
        
        As I envision future collections, I think of each as
        another chapter of this journey. Next, perhaps, there will be a line of
        minimalist adornments or outerwear designed for individuals who
        understand that elegance is about identity rather than ornamentation.
        With each release, I hope to invite our clients into this mindset of
        understated refinement. For it isn’t just luxury we offer but a
        grounding philosophy—an invitation to recognize grace as a reflection of
        self-respect and consideration for others.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-1 gap-4 lg:mb-10">
        <div className="relative overflow-hidden">
          <motion.img
            src="https://res.cloudinary.com/deofmlywm/image/upload/v1730890489/must_use_talking_about_colection_2_gibzrb.webp"
            alt="Fashion Look 1"
            width={500}
            height={500}
            loading="lazy"
            className="w-full h-auto object-cover transition-transform duration-500"
            whileInView={{ scale: 1.1, opacity: 1 }} // Scale up and fade in when in view
            initial={{ scale: 2, opacity: 0 }} // Start at normal size and fully transparent
            exit={{ scale: 1, opacity: 0 }} // Scale down and fade out when out of view
            transition={{ duration: 0.5 }}
          />
        </div>
      </div>

      <p className="text-justify leading-7 mt-6 max-w-5xl mx-auto">

        The notion that grace and restraint are paths to self-fulfillment is one
        I’ve found echoed in African folklore. Take, for instance, the ancient
        Akan story of the spider Anansi, who, through cleverness and patience,
        gathers wisdom. The wisdom that Anansi collects is not flashy or bold;
        it is patient, careful, and transformative in ways that only simplicity
        can be. Like my friend’s influence on me, this lore of wisdom quietly
        and steadily instills dignity and depth in one's character.
        
        <br /> <br />
        
         Another
        powerful tale is that of the Yoruba goddess Oshun, revered for her
        unmatched beauty and gentle power. Oshun’s grace lies in her quiet
        resilience, her sense of self that never seeks the validation of others
        but shines brightly regardless. She embodies grace through restraint and
        inner strength, and her story inspires how I envision each piece in the
        Fash line—a timeless elegance that speaks for itself.  
        <br /> <br />
        
        
        Our first
        collection, EOG, celebrates these qualities, inviting our wearers into
        the depth of self-awareness and restraint that true elegance embodies.
        The designs are a tribute to those who, in understanding their essence,
        choose grace over excess, integrity over illusion, and refinement over
        extravagance. For in a world where so much is spoken without meaning,
        sometimes simplicity can be the truest and most profound expression of
        one’s most valuable nature.      <br /> <br />
        
        In the Igbo tale of the Sky and the Earth,
        there is a lesson about harmony. The Sky, who looks down on Earth with
        adjoint, eventually offers gifts of rain, sun, and seasons that enrich
        the soil. This symbiotic relationship—a dialogue of generosity and
        trust—reminds me of the mutuality in my friendship, where guidance,
        inspiration, and care flowed freely. Just as the Earth flourishes under
        the Sky’s gifts, I became who I am through her grounding presence.     <br /> <br />
        
        The
        journey to creating Expression of Grace 2024 began with a step out of my
        parents’ house. There was a quiet thrill in the air when I finally
        closed that chapter, leaving the familiar warmth of home and stepping
        into an uncharted world—a world in which I was finally free to shape my
        own space and life. It wasn’t a sudden move; it had been years in the
        making, but this moment held an undeniable significance. The timing felt
        perfect for EOG, the collection that had lived in sketches and ideas, in
        visions and conversations, to finally take on its own life. Leaving home
        and entering society as an independent person felt as though I were
        emerging from one cocoon into another, not just moving into my own place
        but also grounding myself in my role as a creator, an active
        contributor, and a voice in the world.      <br /> <br />
        
        
        During my university days, I
        started to notice myself exploring bolder roads—roads that didn’t always
        align with who I wanted to be. The constant presence of my friend kept
        me grounded, reminding me, through her grace, what kind of man I aspired
        to become. Her friendship became my anchor, a source of guidance when my
        steps faltered, and an enduring standard of modesty, elegance, and
        strength. This relationship was a constant reminder that integrity had
        to be the cornerstone of any future success. 

        <br /> <br />
        
        Moving out signified a
        profound shift. It was not just about personal freedom; it was about
        discovering and owning my principles, defining the life I wanted to
        live, and committing to the standards I wanted Fash to represent. I
        started seeing how my desire for simplicity and refinement in my
        personal life was closely intertwined with the very ethos I wanted Fash
        to embody. To step into my own space, I wanted surroundings that
        breathed calm, clarity, and a sense of luxury that was understated, not
        showy. Fash, I realized, had to communicate this same ethos—a brand that
        was as much a lifestyle as it was a style, a philosophy of grace and
        modesty. 
        
        <br /> <br />
        
        EOG’s release felt fateful, almost as if this collection had
        been waiting for the very moment it got released. Every piece, from the
        fabric to the cuts, was designed with this clarity of vision and
        independence in mind. The men’s line reflects the discipline and
        steadiness that come with taking ownership of oneself—simple lines,
        timeless cuts, fabrics that breathe comfort and understated strength.
        And in the women’s footgear, the touchpoints of elegance and femininity
        are not loud but quietly, confidently refined. This collection was about
        creating a luxurious experience of simplicity and sophistication,
        clothing that tells the world you know who you are and what you stand
        for, without the need for embellishment.
        <br /> <br />
        
         Expression of Grace 2024 is
        more than a first collection; it’s the foundation of Fash’s vision. The
        timing of its release marks a turning point in my life, a moment of
        stepping into myself and offering others a philosophy in fabric. It’s
        for those who, like me, seek clarity of expression and who understand
        that true luxury lies not in what one has, but in who one becomes.   <br /> <br />
        
        
        Fash
        is made for men to express grit and clear adoption of purpose while
        existing in a singlemindedness embodying purpose, and Fash is made for
        women devout in their feminine class who exist to inspire and create
        greatness.
      </p>
    </div>
  );
};

export default AboutUs;
