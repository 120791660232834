import React, { useEffect, useState } from 'react';
import { HiExternalLink } from 'react-icons/hi';
import { Link } from 'react-router-dom';

const RecentOrders = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    fetch('https://fash-backend-1.onrender.com/order')
      .then((response) => response.json())
      .then((data) => {
        const sortedOrders = data
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort by most recent
          .slice(0, 5); // Limit to the top 5 orders
        setOrders(sortedOrders);
      })
      .catch((error) => {
        console.error('Error fetching orders:', error);
      });
  }, []);

  return (
    <div className="bg-white rounded-lg shadow-md p-6 col-span-1 relative">
      <h2 className="text-lg font-bold">Recent Orders</h2>
      <Link to="/admin/orders" className="absolute top-4 right-4 text-black">
        <HiExternalLink size={20} />
      </Link>
      <ul className="mt-4 space-y-4">
        {orders.map((order, index) => (
          <li key={index} className="flex justify-between items-center">
            <div className="flex items-center">
              <div>
              <p className="text-sm font-semibold">{order.name}</p>

                <p className="text-xs text-gray-500">{order.reference}</p>
              </div>
            </div>
            <div className="text-right">
              <p className="text-sm">${order.amount}</p>
              <p
  className={`text-sm font-semibold ${
    order.orderStatus.toUpperCase() === 'FAILED'
      ? 'text-red-500'
      : order.orderStatus.toUpperCase() === 'REFUNDED'
      ? 'text-yellow-500'
      : 'text-green-500'
  }`}
>
  {order.orderStatus.toUpperCase()}
</p>

            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentOrders;
