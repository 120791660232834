// src/data/faqData.js
export const faqData = [
    {
      question: "What is the return policy?",
      answer: "You can return any item within 30 days of purchase if it's unworn and in its original packaging. Refunds are processed within 7 days of receiving the item."
    },
    {
      question: "How long does shipping take?",
      answer: "Standard shipping takes 5-7 business days, while express shipping takes 2-3 business days. International shipping times may vary."
    },
    {
      question: "What payment methods are accepted?",
      answer: "We accept all major credit cards Visa, MasterCard, American Express"
    },
    {
      question: "Can I track my order?",
      answer: "Yes, once your order is shipped, you will receive a tracking number via email, which can be used to track your package in real-time."
    },
    {
      question: "Do you offer international shipping?",
      answer: "Yes, we ship internationally! Shipping fees and delivery times may vary depending on the destination."
    },
    {
      question: "How do I find my size?",
      answer: "You can refer to our size chart, available on each product page. If you're in between sizes, we recommend sizing up for a more comfortable fit."
    },
    {
      question: "What should I do if I received the wrong item?",
      answer: "If you received the wrong item, please contact our customer service team within 7 days of delivery. We’ll arrange for a replacement at no additional cost."
    },
    {
      question: "Are there any care instructions for your clothing?",
      answer: "Yes, each item comes with specific care instructions on its label. We also provide a general care guide on our website for different types of fabrics."
    },
    {
      question: "How can I cancel or change my order?",
      answer: "Orders can be modified or canceled within 1 hour of placing them. After this window, the order will be processed. Contact us immediately if you need to make a change."
    },
 
    {
      question: "What happens if my package is lost or damaged?",
      answer: "If your package is lost or damaged during transit, please contact our support team with your order number, and we'll investigate and offer a solution, such as a replacement or refund."
    },
    {
      question: "How do I contact customer support?",
      answer: "You can reach our customer support team via the Contact Us page, or by emailing order@fashattire.com We aim to respond within 24 hours."
    },
 
    {
      question: "What should I do if my discount code isn’t working?",
      answer: "Ensure the code is correctly entered and hasn't expired. Some codes are single-use or have conditions. If issues persist, contact our support team for assistance."
    },
    {
      question: "Can I return items from a sale?",
      answer: "Items marked as 'final sale' cannot be returned. For other discounted items, our standard return policy applies."
    },
   
  ];
  