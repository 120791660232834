import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { HiExternalLink } from "react-icons/hi";
import { Link } from 'react-router-dom';
import axios from 'axios';

// Register the necessary components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TopProducts = () => {
  const [topProductsData, setTopProductsData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Top 3 Products',
        data: [],
        backgroundColor: ['#4B5563', '#9CA3AF', '#D1D5DB'],
      },
    ],
  });

  useEffect(() => {
    // Fetch the top 3 products data from the API
    axios.get('https://fash-backend-1.onrender.com/item/top')
      .then(response => {
        const products = response.data.message;

        // Extract product names and their quantities
        const productNames = products.map(product => product.name);
        const productQuantities = products.map(product => product.quantity);

        // Update the chart data
        setTopProductsData({
          labels: productNames,
          datasets: [
            {
              label: 'Top 3 Products',
              data: productQuantities,
              backgroundColor: ['#4B5563', '#9CA3AF', '#D1D5DB'],
            },
          ],
        });
      })
      .catch(error => {
        console.error("Error fetching top products:", error);
      });
  }, []);

  return (
    <div className="bg-white rounded-lg shadow-md p-6 relative">
      <h2 className="text-lg font-bold">Top 3 Products</h2>
      <p className="text-sm text-gray-400">In the last 6 months</p>
      <Link to="/admin/product" className="absolute top-4 right-4 text-black">
        <HiExternalLink size={20} />
      </Link>
      <div className="mt-4">
        <Bar data={topProductsData} />
      </div>
    </div>
  );
};

export default TopProducts;
